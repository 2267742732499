import React from "react";
import styled from "styled-components";

interface CheckboxProps {
  label: React.ReactNode;
  defaultValue?: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
  borderColor: string;
  checkboxColor: string;
}

interface CheckboxState {
  checked: boolean;
}

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = { checked: this.props.defaultValue === true };
  }

  componentWillReceiveProps(newProps: CheckboxProps) {
    this.setState({ checked: newProps.defaultValue === true });
  }

  onClick = () => {
    this.setState({ checked: !this.state.checked });
    this.props.onChange && this.props.onChange(!this.state.checked);
  };

  render() {
    return (
      <Container onClick={() => this.onClick()} className={this.props.className}>
        <Span checked={this.state.checked} borderColor={this.props.borderColor} checkboxColor={this.props.checkboxColor} />
        <Label>
          {this.props.label}
        </Label>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.label`
  cursor: pointer;
  user-select: none;
  text-align: left;
`;

const Span = styled.span<{ checked: boolean, borderColor: string, checkboxColor: string }>`
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  background-color: transparent;
  width: 18px;
  height: 18px;
  transform-origin: center;
  border: 1px solid ${p => p.borderColor};
  border-radius: 2px;
  vertical-align: -6px;
  margin-right: 1rem;
  transition: background-color 150ms 200ms,
              transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89),
              border-radius 150ms ease;

  &:before {
    content: "";
    width: 0px;
    height: 2px;
    border-radius: 0px;
    background: ${p => p.borderColor};
    position: absolute;
    transform: rotate(45deg);
    top: 8px;
    left: 5px;
    transition: width 50ms ease, background-color 50ms ease 50ms;
    transform-origin: 0% 0%;
  }

  &:after {
    content: "";
    width: 0;
    height: 2px;
    border-radius: 0px;
    background: ${p => p.borderColor};
    position: absolute;
    transform: rotate(305deg);
    top: 12px;
    left: 6px;
    transition: width 50ms ease, background 50ms ease 50ms;
    transform-origin: 0% 0%;
  }

  ${p => p.checked ? `
    background-color: ${p.borderColor};
    transform: scale(1.4);
    border-radius: 50%;
    &:after {
        width: 10px;
        background: ${p.checkboxColor};
        transition: width 150ms ease 100ms, background 150ms ease 250ms;
    }
    
    &:before {
        width: 5px;
        background:  ${p.checkboxColor};
        transition: width 150ms ease 100ms, background 150ms ease 250ms;
      }

      &:hover {
          background: ${(p.borderColor)};
          transform: scale(1.4);

          &:after {
            width: 10px;
            background: ${p.checkboxColor};
            transition: width 150ms ease 100ms, background 150ms ease 250ms;
          }

          &:before {
            width: 5px;
            background:  ${p.checkboxColor};
            transition: width 150ms ease 100ms, background 150ms ease 250ms;
          }
        }  
      }`
    : `
  @media (min-width: 1200px) {
    &:hover {
      &:before {
          width: 5px;
          transition: width 100ms ease;
      }

      &:after {
          width: 10px;
          transition: width 150ms ease 100ms;
      }
    }
  }`
  }
`;
