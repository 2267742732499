import React from 'react';
import { Container } from 'react-bootstrap';
import * as ConfigureReactWidgets from "./ConfigureReactWidgets"
import './App.css';
import { Loader } from './components/common/Loader';
import { MainError } from './components/MainError';
import { MainLoader } from './components/MainLoader';
import { MainMessage, IMainMessage } from './components/MainMessage';
import { APIClient } from './shared/ApiClient';
import { JwtClientToken, RatingDto, UserInfo } from './shared/Client';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import AppConfiguration from './components/AppConfiguration';
import NavMenu from './components/NavMenu'
import Home from './pages/Home';
import Login from './pages/Login';
import Detail from './pages/Detail';
import Evaluate from './pages/Evaluate';
import Rating from './pages/Rating';
import UserManagement from './pages/UserManagement';
import UserSettings from './pages/UserSettings';
import ResetPassword from './pages/ResetPassword';
import DocumentViewer from './components/documentviewer/DocumentViewer';
import PartnerView from './pages/PartnerView'
import ToDo from './pages/ToDo'
import Assign from './pages/Assign'
import { AppParameters, getAppParameters } from './components/AppParameters';

ConfigureReactWidgets.configure();

export enum ScreenView {
  Home,
  Login,
  ResetPassword,
  Evaluate,
  UserManagement,
  UserSettings,
  Rating,
  Detail,
  Register,
  Loading,
  DocumentViewer,
  PartnerView,
  Logout,
  ToDo,
  Assign
}

interface AppState {
  screen: ScreenView,
  currentUser?: UserInfo;
  appConfiguration?: AppConfiguration;
  appParameters: AppParameters;
  apiClient?: APIClient;
  mainMessageProps: IMainMessage;
  ratings?: RatingDto[];
  loading: boolean;
  selectedReceiptId?: string;
  selectedUserId?: string;
  regulatorName?: string;
}

interface AppProps {
}


export default class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      screen: ScreenView.Loading,
      loading: true,
      appParameters: getAppParameters(),
      mainMessageProps: { show: false } as IMainMessage
    }
  }

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event)
    {
      window.history.pushState(null, document.title,  window.location.href);
    });
  }

  init = async () => {
    const appConfiguration = await fetch('config.json').then(response => (response.json() as unknown) as AppConfiguration);
    const apiClient = new APIClient(appConfiguration.backendConfiguration.basePath, appConfiguration.backendConfiguration.apiKey);
    this.setState({ appConfiguration, apiClient });
    let storage = localStorage.getItem('PropertyExpert.ExpertiseCheck.Token');
    let appParams = getAppParameters();
    let documentId = appParams.documentId;
    let receiptId = appParams.receiptId;
    let userId = appParams.userId;

    if (storage) {
      let jwtClientToken: JwtClientToken = JSON.parse(storage);
      if (jwtClientToken) {
        let currentUser = jwtClientToken.user;
        let validateToken = await apiClient.validate(jwtClientToken);
        if (validateToken === false) {
          localStorage.removeItem('PropertyExpert.ExpertiseCheck.Token');
          this.setState({ currentUser: undefined, screen: ScreenView.Login });
        }
        else {
          if (appParams) {
            const ratings = await apiClient.getRatings(currentUser!.token!, currentUser!.id!);
            this.setState({ratings});
            if (documentId) {
              this.setState({ selectedReceiptId: documentId, currentUser, screen: ScreenView.DocumentViewer });
            }
            else if (receiptId) {
              this.setState({ selectedReceiptId: receiptId, screen: ScreenView.PartnerView });
            }
            else {
              this.setState({ currentUser, screen: ScreenView.Home });
            }
          }
          else {
            this.setState({ currentUser, screen: ScreenView.Home });
          }
        }
      }
    }
    else {
      if (documentId) {
        this.setState({ selectedReceiptId: documentId, currentUser: undefined, screen: ScreenView.DocumentViewer });
      }
      else if (receiptId) {
        this.setState({ selectedReceiptId: receiptId, currentUser: undefined, screen: ScreenView.PartnerView });
      }
      else if (userId) {
        this.setState({ selectedUserId: userId, currentUser: undefined, screen: ScreenView.ResetPassword });
      }
      else {
        this.setState({ currentUser: undefined, screen: ScreenView.Login });
      }
    }
  }

  onLogin = (currentUser: UserInfo) => {
    if (currentUser) {
      this.setState({ currentUser, screen: ScreenView.Home });
      this.init();
    }
  }

  onHomeClose = (regulatorName : string) => {
    this.setState( {regulatorName : regulatorName});
  }

  onResetPassword = () => {
    this.setState({screen : ScreenView.Login})
  }

  openReceipt = (selectedReceiptId: string) => {
    if (selectedReceiptId) {
      this.setState({ selectedReceiptId, screen: ScreenView.Detail });
    }
  }

  onDetailClose = () => {
    
    this.setState({ screen: ScreenView.Home });
  }

  onNavMenuClick = (screenView: ScreenView) => {
    if (screenView === ScreenView.Login) {
      this.setState({ currentUser: undefined });
    }
    this.setState({ screen: screenView });
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Loader
          loader={async () => { this.init() }}
          onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
          onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
          onSuccess={
            <>
              <div style={{ backgroundColor: '#f0f0f7' }}>
                <NavMenu
                  ratings={this.state.ratings}
                  onNavMenuClick={(screenView: ScreenView) => { this.onNavMenuClick(screenView); }}
                  currentUser={this.state.currentUser!}
                />
                <Container fluid='xl' style={{paddingTop: '85px', paddingBottom: '1rem'}}>
                  {this.state.screen === ScreenView.Loading &&
                    <MainLoader message="Ihre Daten werden geladen..." />
                  }
                  {this.state.screen === ScreenView.Login &&
                    <Login
                      onLogin={(currentUser: UserInfo) => this.onLogin(currentUser)}
                      currentUser={this.state.currentUser!}
                      apiClient={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.ResetPassword &&
                    <ResetPassword
                      onResetPassword={() => this.onResetPassword()}
                      selectedUserId={this.state.selectedUserId!}
                      apiClient={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.ToDo &&
                   <ToDo 
                    currentUser={this.state.currentUser!}
                    apiClient = {this.state.apiClient!}
                   />
                  }
                  {this.state.screen === ScreenView.Home &&
                    <Home
                      onHomeClose={(regulatorName : string) => this.onHomeClose(regulatorName)}
                      preSetFilter={this.state.regulatorName}
                      openReceipt={(receiptId: string) => this.openReceipt(receiptId)}
                      currentUser={this.state.currentUser!}
                      apiClient={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.Detail &&
                    <Detail
                      onClose={() => this.onDetailClose()}
                      receiptId={this.state.selectedReceiptId!}
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.Assign &&
                    <Assign
                      onClose={() => this.onDetailClose()}
                      receiptId={this.state.selectedReceiptId!}
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.Evaluate &&
                    <Evaluate
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.Rating &&
                    <Rating
                      ratings={this.state.ratings}
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.UserManagement &&
                    <UserManagement
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.UserSettings &&
                    <UserSettings
                      onClose={() => this.setState({ screen: ScreenView.Home })}
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.selectedReceiptId && this.state.screen === ScreenView.DocumentViewer &&
                    <DocumentViewer
                      receiptId={this.state.selectedReceiptId!}
                      currentUser={this.state.currentUser!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                  {this.state.screen === ScreenView.PartnerView &&
                    <PartnerView
                      receiptId={this.state.selectedReceiptId!}
                      client={this.state.apiClient!}
                      appConfiguration={this.state.appConfiguration!}
                      mainMessageProps={this.state.mainMessageProps}
                      editMainMessage={(mainMessageProps: IMainMessage) => this.setState({ mainMessageProps })}
                    />
                  }
                </Container>
                <MainMessage
                  show={this.state.mainMessageProps!.show}
                  message={this.state.mainMessageProps!.message}
                  variant={this.state.mainMessageProps!.variant!}
                  onModalClose={() =>
                    this.setState({ mainMessageProps: { show: false } as IMainMessage })
                  }
                />
              </div>
            </>
          }
        />
      </ThemeProvider>
    )
  }
}