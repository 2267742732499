import * as React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import theme from '../theme/theme';
import { APIClient } from "../shared/ApiClient";
import { ReceiptDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { H2 } from '../components/common/H2';
import { H3 } from '../components/common/H3';
import { Checkbox } from '../components/common/Checkbox';

interface AdditionalInfoViewState {
    comment?: string;
}

interface AdditionalInfoViewProps {
    onLoad: (loading: boolean) => void;
    onChange: (comment: string) => void;
    onChecked: (checked: boolean) => void;
    receipt: ReceiptDto;
    client: APIClient;
    appConfiguration: AppConfiguration;
}

export default class AdditionalInfoView extends React.Component<AdditionalInfoViewProps, AdditionalInfoViewState> {
    constructor(props: AdditionalInfoViewProps) {
        super(props);

        this.state = {
        
        }
    }

    render() {
        let receipt = this.props.receipt;
        return (
            <>
                <Row md={12} style={{
                    border: '1px solid rgba(0, 0, 0, 0.125)',
                    paddingTop: '1rem',
                    marginTop: '2rem',
                    paddingBottom: '1rem',
                    backgroundColor: '#fff',
                    color: '#000'
                }}>
                    <Col md={4}>
                        <H2 style={{ fontSize: '1.25rem', fontWeight: 'bolder' }}>Erläuterung</H2>
                    </Col>
                    <Col md={8}><H2></H2></Col>
                </Row>
                <Row md={12} style={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    backgroundColor: '#f8f8f8',
                    color: '#000',
                    borderBottom: 'solid 1px #dee2e6'
                }}>
                    <Col md={4}><H2>Verhandlung Info</H2></Col>
                    <Col md={8}><H3>{receipt.negotiation}</H3></Col>
                </Row>
                <Row md={12} style={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    backgroundColor: '#f8f8f8',
                    color: '#000',
                    borderBottom: 'solid 1px #dee2e6'
                }}>
                    <Col md={4}><H2>Handwerker</H2></Col>
                    <Col md={8}><H3>{receipt.craftsman}</H3></Col>
                </Row>
                <Row md={12} style={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    backgroundColor: '#f8f8f8',
                    color: '#000',
                    borderBottom: 'solid 1px #dee2e6'
                }}>
                    <Col md={4}><H2>Ergebnis Info</H2></Col>
                    <Col md={8}><H3>{receipt.resultInformation}</H3></Col>
                </Row>

                <Row md={12} style={{
                    border: '1px solid rgba(0, 0, 0, 0.125)',
                    paddingTop: '1rem',
                    marginTop: '2rem',
                    paddingBottom: '1rem',
                    backgroundColor: '#fff',
                    color: '#000'
                }}>
                    <Col md={12}>
                        <H2 style={{ fontSize: '1.25rem', fontWeight: 'bolder' }}>Sonstige Anmerkungen / weitere Informationen:</H2>
                    </Col>
                    <Col md={12}>
                        <Form.Control as="textarea" 
                            onBlur={(e: any)=> this.props.onChange(e.target.value)}
                            defaultValue={this.props.receipt.comment} />
                    </Col>
                    <Col md={3}></Col>
                    <Col md={6} style={{ marginTop: '1rem' }}>
                        <Form.Check 
                            style={{color:theme.colors.secondary}}
                            type={'checkbox'}
                            label={'Beleg wird neu angefordert (bitte Begründung im Freitextfeld hinterlegen)'}
                            onChange={(e: any) => {
                                this.props.onChecked(e.target.checked);
                            }}
                        />
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </>
        )
    }
}