import React from 'react';

interface LoaderProps<T> {
    loader: () => Promise<T>;
    onLoad: React.ReactElement;
    onSuccess: React.ReactElement;
    onError: React.ReactElement;
}

interface LoaderState<T> {
    object?: T;
    state: "Initial" | "Loading" | "Success" | "Error";
}

export class Loader<T> extends React.Component<LoaderProps<T>, LoaderState<T>> {
    constructor(props: LoaderProps<T>) {
        super(props);

        this.state = { state: "Initial" };
    }

    componentWillMount() {
        this.setState({ state: "Loading" }, async () => {
            try {
                const object = await this.props.loader()
                this.setState({ object, state: "Success" })
            } catch (e) {
                this.setState({ state: "Error" });
            }
        });
    }

    render() {
        return (
            <>
                {this.state.state === "Loading" && this.props.onLoad}
                {this.state.state === "Success" && this.props.onSuccess}
                {this.state.state === "Error" && this.props.onError}
            </>);
    }
}