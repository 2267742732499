import * as React from 'react';
import { Card, Form, Row, Col, Button, Modal, ListGroupItem, ListGroup, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { APIClient } from "../shared/ApiClient";
import { ReceiptDto, DepartmentDto, ReceiptSearchDto, UserInfo, RegulatorDto, SortEnum, PartnerDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { H3 } from '../components/common/H3';
import { H2 } from '../components/common/H2';
import { Checkbox } from '../components/common/Checkbox';
import theme from '../theme/theme';
import { IMainMessage } from '../components/MainMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

interface HomeState {
    cases: ReceiptDto[],
    departments: DepartmentDto[],
    partners: PartnerDto[],
    regulators: RegulatorDto[];
    searchedRegulators: RegulatorDto[];
    loading: boolean;
    loadingList: boolean;
    abgeschlossen: boolean;
    wartend: boolean;
    currentPage: number;
    count: number;
    maxPages: number;
    damageNumber?: string;
    regulatorName?: string;
    department?: string;
    partner?: string;
    documentTyp?: string;
    regulator?: string;
    showDeleteModal: boolean;
    showRegulatorModal: boolean;
    toSwitchReceiptId?: string;
    toDeleteReceiptId?: string;
    toHoverReceiptId: string;
    arrowCircle: boolean;
    ownReceipts: boolean;
    selectReceipts: string;
}

interface HomeProps {
    onHomeClose: (regulatorName : string) => void;
    preSetFilter?: string;
    openReceipt: (receiptId: string) => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
    currentUser: UserInfo;
    appConfiguration: AppConfiguration;
    apiClient: APIClient;
}

export default class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);

        this.state = {
            cases: [],
            arrowCircle: true,
            loading: true,
            loadingList: false,
            showDeleteModal: false,
            showRegulatorModal: false,
            departments: [],
            regulators: [],
            searchedRegulators: [],
            partners: [],
            abgeschlossen: false,
            wartend: false,
            currentPage: 1,
            maxPages: 1,
            count: 1,
            toHoverReceiptId: '',
            ownReceipts: true,
            selectReceipts: 'openreceipts',
            regulatorName: this.props.preSetFilter
        }
    }

    componentDidMount(): void {
        this.init();
    }

    init = async () => {
        console.log(this.props.currentUser!);
        const departments = await this.props.apiClient!.getDepartments(this.props.currentUser.token!, this.props.currentUser!.customerIds!);
        this.setState({ departments });

        const regulators = await this.props.apiClient.getRegulators(this.props.currentUser!.token!, this.props.currentUser!);
        this.setState({ regulators });
        this.setState({ searchedRegulators: regulators });

        this.searchReceipts();
    }

    searchReceipts = async (caller?: string, sortType?: string, arrowCircle?: boolean) => {
        
        if (caller && this.state.selectReceipts === caller) {
            return;
        }
        else {
            this.setState({ selectReceipts: caller! });
        }

        let ownReceipts = true;
        if(caller === "allreceipts"){
            ownReceipts = false;
            this.setState({ ownReceipts: false });
        } 
        if(caller === "myreceipts"){
            ownReceipts = true;
            this.setState({ ownReceipts: true });
        }

        let sortDirection = 0;

        if (arrowCircle === false)
            sortDirection = 0;
        else
            sortDirection = 1;

        let sortBy = SortEnum._5;

        if (sortType === "date")
            sortBy = SortEnum._5;
        if (sortType === "partner")
            sortBy = SortEnum._7;
        if (sortType === "damagenumber")
            sortBy = SortEnum._1;

        this.setState({ loadingList: true });

        const receiptSearch = {
            customerId: this.props.currentUser!.customerIds,
            abgeschlossen: this.state.abgeschlossen,
            currentPage: this.state.currentPage,
            damageNumber: this.state.damageNumber,
            regulatorName: this.state.regulatorName,
            wartend: this.state.wartend,
            department: this.state.department,
            userId: this.props.currentUser!.id,
            teamLead: this.props.currentUser!.teamlead,
            sortBy: sortBy,
            sortDirection: sortDirection,
            regulator: this.state.regulator,
            partner: this.state.partner,
            documentType: this.state.documentTyp,
            ownReceipts: ownReceipts
        } as ReceiptSearchDto;

        const receiptSearchList = await this.props.apiClient!.searchReceipts(this.props.currentUser!.token!, receiptSearch);
        if (receiptSearchList.count! < 20) {
            this.setState({ currentPage: 1, maxPages: 1 })
        }
        debugger;
        if (receiptSearchList) {
            this.setState(
                {
                    cases: receiptSearchList.receipts!,
                    partners: receiptSearchList.partners!,
                    loadingList: false,
                    loading: false,
                    count: receiptSearchList.count!,
                    maxPages: Math.round((receiptSearchList.count! / 20))
                });
        }
    }

    switchReceiptRelation = async (receiptId: string, regulatorId: string) => {
        this.setState({ showRegulatorModal: false });
        await this.props.apiClient.setRegulator(this.props.currentUser!.token!, receiptId, regulatorId);
        this.searchRegulator(undefined);
        this.searchReceipts();
    }

    deleteReceiptRelation = async (receiptId: string) => {
        const client = this.props.apiClient;
        await client.deleteReceipt(this.props.currentUser!.token!, receiptId);
        this.searchReceipts();
    }

    openReceipt = (receiptId?: string) => {
        if (receiptId === '00000000-0000-0000-0000-000000000000') {
        }
        else {
            this.props.openReceipt(receiptId!);
        }
    }

    onMouseOver = (receiptId: string) => {
        if (receiptId === '00000000-0000-0000-0000-000000000000') {

        }
        else {
            this.setState({ toHoverReceiptId: receiptId });
        }

    }

    onMouseLeave = (receiptId: string) => {
        this.setState({ toHoverReceiptId: '' });
    }

    searchRegulator = async (searchKey?: string) => {
        if (searchKey === undefined) {
            const client = this.props.apiClient;
            const searchedRegulators = await client.getRegulators(this.props.currentUser!.token!, this.props.currentUser!);
            this.setState({ searchedRegulators });
        }
        else {
            const client = this.props.apiClient;
            let searchedRegulators = await client.searchRegulator(this.props.currentUser!.token!, searchKey!, this.props.currentUser!);
            this.setState({ searchedRegulators });
        }
    }

    render() {
        return (
            <Loader
                loader={async () => { /*this.init();*/ }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                    
                        {this.state.loading && <MainLoader message="Ihre Daten werden geladen..." />}

                        {!this.state.loading &&
                            <Card>
                                <Card.Body>
                                    <Row md={12}>
                                        <Col md={5}>
                                            <Form.Group>
                                                <H2>Regulierername</H2>
                                                <Form.Control type="text"
                                                    defaultValue={this.state.regulatorName}
                                                    onKeyDown={(e:any) => {
                                                        if (e.key === 'Enter') {
                                                            if (e.target.value.length > 0)
                                                            {
                                                                this.setState({ regulatorName: e.target.value }, () => this.searchReceipts());
                                                                this.props.onHomeClose(e.target.value);
                                                            }
                                                            else
                                                            {
                                                                this.setState({ regulatorName: "" }, () => this.searchReceipts());
                                                                this.props.onHomeClose(e.target.value);
                                                            }
                                                        }
                                                    }}
                                                    onBlur={(e: any) => {
                                                        if (e.target.value.length > 0)
                                                        {
                                                            this.setState({ regulatorName: e.target.value }, () => this.searchReceipts());
                                                            this.props.onHomeClose(e.target.value);
                                                        }
                                                        else
                                                        {
                                                            this.setState({ regulatorName: "" }, () => this.searchReceipts());
                                                            this.props.onHomeClose(e.target.value);
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={5}>
                                            <Form.Group>
                                                <H2>Schadennummer</H2>
                                                <Form.Control type="text"
                                                    defaultValue={this.state.damageNumber}
                                                    onKeyDown={(e:any) => {
                                                        if (e.key === 'Enter') {
                                                            if (e.target.value.length > 0)
                                                                this.setState({ damageNumber: e.target.value }, () => this.searchReceipts());
                                                            else
                                                                this.setState({ damageNumber: "" }, () => this.searchReceipts());
                                                        }
                                                    }}
                                                    onBlur={(e: any) => {
                                                        if (e.target.value.length > 0)
                                                            this.setState({ damageNumber: e.target.value }, () => this.searchReceipts());
                                                        else
                                                            this.setState({ damageNumber: "" }, () => this.searchReceipts());
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                        <Form.Group>
                                        <H2>Suchen</H2>
                                                <Button
                                                    variant="primary"
                                                    style={{minWidth:'12rem', cursor:'pointer'}}
                                                    onClick={() => {
                                                        this.searchReceipts();
                                                    }}>
                                                    Suchen
                                                </Button>
                                                </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row md={12}>
                                        <Col md={3}>
                                            <Form.Group>
                                                <H2>Gruppe</H2>
                                                <Form.Control as="select"
                                                    readOnly={!this.props.currentUser!.teamlead}
                                                    disabled={!this.props.currentUser!.teamlead}
                                                    onChange={(e: any) => {
                                                        this.setState({ department: e.target.value }, () => this.searchReceipts());
                                                    }}>
                                                    <option value="">Alle</option>
                                                    {this.state.departments.map(c => (
                                                        <option key={c.name} value={c.name}>{c.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <H2>Dokumentart</H2>
                                                <Form.Control as="select"
                                                    onChange={(e: any) => {
                                                        this.setState({ documentTyp: e.target.value }, () => this.searchReceipts());
                                                    }}>
                                                    <option value="">Alle</option>
                                                    <option key={"Angebot"} value={"Angebot"}>Angebot</option>
                                                    <option key={"Rechnung"} value={"Rechnung"}>Rechnung</option>
                                                </Form.Control>
                                            </Form.Group>

                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <H2>Regulierer</H2>
                                                <Form.Control as="select"
                                                    onChange={(e: any) => {
                                                        this.setState({ regulator: e.target.value }, () => this.searchReceipts());
                                                    }}>
                                                    <option value="">Alle</option>
                                                    {this.state.regulators && this.state.regulators.map(c => (
                                                        <option key={c.guid} value={c.guid}>{c.firstName}{" "}{c.lastName}{" ("}{c.userName}{")"}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <H2>Partner</H2>
                                                <Form.Control as="select"
                                                    onChange={(e: any) => {
                                                        this.setState({ partner: e.target.value }, () => this.searchReceipts());
                                                    }}>
                                                    <option value="">Alle</option>
                                                    {this.state.partners.map(c => (
                                                        <option key={c.name} value={c.name}>{c.name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} style={{ textAlign: 'right' }}>
                                            <Row md={12}>
                                                <Col md={4}>
                                                    <Checkbox
                                                        label={<H2>Wartende Belege einbeziehen</H2>}
                                                        checkboxColor={theme.colors.text.primary}
                                                        borderColor={theme.colors.primary}
                                                        defaultValue={this.state.wartend}
                                                        onChange={(checked) => {
                                                            this.setState({ wartend: checked }, () => this.searchReceipts());
                                                        }}
                                                    />
                                                </Col>
                                                {!this.props.currentUser.teamlead &&
                                                    <Col md={4}>
                                                        <ToggleButtonGroup type="radio" name="options" defaultValue={"myreceipts"} style={{ float: 'left', padding: '1rem' }}>
                                                            <ToggleButton value={"myreceipts"} checked={this.state.ownReceipts}
                                                                onClick={(e: any) => {
                                                                    this.setState({ ownReceipts: true }, () => this.searchReceipts('myreceipts'));
                                                                }}>
                                                                Meine Belege
                                                            </ToggleButton>
                                                            <ToggleButton value={"allreceipts"} checked={this.state.ownReceipts}
                                                                onClick={(e: any) => {
                                                                    this.setState({ ownReceipts: false }, () => this.searchReceipts('allreceipts'));
                                                                }}>
                                                                Alle Belege
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </Col>
                                                }
                                                <Col md={4}>
                                            
                                                    <ToggleButtonGroup type="radio" name="options" defaultValue={this.state.selectReceipts} style={{ float: 'left', padding: '1rem' }}>
                                                        <ToggleButton value={"openreceipts"} 
                                                            
                                                            checked={this.state.selectReceipts === "openreceipts"}
                                                            onClick={(e: any) => {
                                                                this.setState({ abgeschlossen: false }, () => this.searchReceipts('openreceipts'));
                                                            }}>
                                                            Offene Belege
                                                            </ToggleButton>
                                                        <ToggleButton value={"closedreceipts"} 
                                                            
                                                            checked={this.state.selectReceipts === "closedreceipts"}
                                                            onClick={(e: any) => {
                                                                this.setState({ abgeschlossen: true }, () => this.searchReceipts('closedreceipts'));
                                                            }}>
                                                            Abgeschlossene Belege
                                                            </ToggleButton>
                                                    </ToggleButtonGroup>
                                                    
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'right' }}>
                                            <H2 style={{ textAlign: 'right' }}>
                                                Gesamte Aufträge: {this.state.count}
                                            </H2>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        }

                        {this.state.loadingList && <MainLoader message="Ihre Daten werden geladen..." />}
                        {!this.state.loadingList &&
                            <>
                                <Card style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                    <Card.Header>
                                        {this.state.maxPages > 1 &&
                                            <div className="d-flex justify-content-between">
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    disabled={this.state.currentPage < 2}
                                                    onClick={() => {
                                                        const currentPage = this.state.currentPage;
                                                        if (this.state.currentPage > 0) {
                                                            this.setState({ currentPage: currentPage - 1 }, () => this.searchReceipts())
                                                        }
                                                    }}>
                                                    Zurück
                                                </Button>
                                                <Form.Text style={{ color: '#0A5699', fontWeight: 'bold' }}>Seite: {this.state.currentPage} / {this.state.maxPages}</Form.Text>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    style={{ float: 'right' }}
                                                    disabled={this.state.currentPage === this.state.maxPages}
                                                    onClick={() => {
                                                        const currentPage = this.state.currentPage;
                                                        if (currentPage <= this.state.maxPages) {
                                                            this.setState({ currentPage: currentPage + 1 }, () => this.searchReceipts())
                                                        }
                                                    }}>
                                                    Weiter
                                                </Button>
                                            </div>
                                        }
                                    </Card.Header>
                                    <Card.Body>
                                        <Row md={12} style={{ fontSize: '11pt', borderBottom: 'solid 1px #dee2e6', fontWeight: 'bold' }}>
                                            <Col md={2}>
                                                <H2 style={{ cursor: "pointer" }}>
                                                    Schadennummer
                                                </H2>
                                            </Col>
                                            <Col md={2}><H2>Versicherter</H2></Col>
                                            <Col md={1}>
                                                <H2 style={{ cursor: "pointer" }}>
                                                    BelegNr.
                                                </H2>
                                            </Col>
                                            <Col md={2}>
                                                <Row md={12}>
                                                    <Col md={6}><H2>Regulierer</H2></Col>
                                                    <Col md={6}>
                                                        <H2
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                this.setState({ arrowCircle: !this.state.arrowCircle }, () => this.searchReceipts('date', "date", this.state.arrowCircle));
                                                            }} >
                                                            Zuordn.-/ <br />
                                                            Einstell-Datum&nbsp;
                                                            {!this.state.arrowCircle &&
                                                                <FontAwesomeIcon size="1x" icon={faArrowUp} />
                                                            }
                                                            {this.state.arrowCircle &&
                                                                <FontAwesomeIcon size="1x" icon={faArrowDown} />
                                                            }
                                                        </H2>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1}><H2>Status</H2></Col>
                                            <Col md={2}>
                                                <H2 style={{ cursor: "pointer" }}>
                                                    Partner
                                                </H2>
                                            </Col>
                                            <Col md={1}><H2>Doku.</H2></Col>
                                            <Col md={1}><H2></H2></Col>
                                        </Row>
                                        {this.state.cases.map((receipt) =>
                                            <Row
                                                md={12}
                                                key={receipt.uniqueId}
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '10pt',
                                                    borderBottom: 'solid 1px #dee2e6',
                                                    paddingTop: '1rem',
                                                    height: '100px',
                                                    minHeight: '100px',
                                                    maxHeight: '100px',
                                                    backgroundColor: receipt.uniqueId === '00000000-0000-0000-0000-000000000000' ? '#f8f8f8' :
                                                        receipt.uniqueId === this.state.toHoverReceiptId
                                                            ? '#003A5E'
                                                            : '#fff',
                                                    color: receipt.uniqueId === '00000000-0000-0000-0000-000000000000' ? '#000'
                                                        : receipt.uniqueId === this.state.toHoverReceiptId
                                                            ? '#fff'
                                                            : '#000'
                                                }}
                                                onMouseOver={(e: any) => { this.onMouseOver(receipt.uniqueId!) }}
                                                onMouseLeave={(e: any) => { this.onMouseLeave(receipt.uniqueId!) }}
                                            >
                                                <Col md={2} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.damageNumber}
                                                    </H3>
                                                </Col>
                                                <Col md={2} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.claimant}
                                                    </H3>
                                                </Col>
                                                <Col md={1} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.number}
                                                    </H3>
                                                </Col>
                                                <Col md={2}>
                                                    <Row md={12}>
                                                        <Col md={7} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                            <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                                {receipt.regulator}
                                                            </H3>
                                                        </Col>
                                                        <Col md={5} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                            <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                                {receipt.inputDateText} <br /> {receipt.finishText}
                                                            </H3>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={1} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.state}
                                                    </H3>
                                                </Col>
                                                <Col md={2} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.partner}
                                                    </H3>
                                                </Col>
                                                <Col md={1} onClick={() => this.openReceipt(receipt.uniqueId)}>
                                                    <H3 style={{ cursor: 'pointer', color: receipt.uniqueId === this.state.toHoverReceiptId ? '#fff' : '#000' }}>
                                                        {receipt.documentType}
                                                    </H3>
                                                </Col>
                                                <Col md={1}>
                                                    <H3>
                                                        {this.props.currentUser!.teamlead &&
                                                            <>
                                                                <Button
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    onClick={() => this.setState({ toSwitchReceiptId: receipt.uniqueId!, showRegulatorModal: true })}>
                                                                    Umbuchen
                                                                </Button>
                                                            </>
                                                        }
                                                    </H3>
                                                    <H3>
                                                        {this.props.currentUser!.teamlead &&
                                                            <Button variant="danger"
                                                                size="sm"
                                                                style={{ width: '85px' }}
                                                                onClick={() => this.setState({ toDeleteReceiptId: receipt.uniqueId!, showDeleteModal: true })}>
                                                                Löschen
                                                            </Button>
                                                        }
                                                    </H3>
                                                </Col>
                                            </Row>
                                        )}
                                    </Card.Body>
                                    {this.state.maxPages > 1 &&
                                        <Card.Footer>
                                            <div className="d-flex justify-content-between">
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    disabled={this.state.currentPage < 2}
                                                    onClick={() => {
                                                        const currentPage = this.state.currentPage;
                                                        if (this.state.currentPage > 0) {
                                                            this.setState({ currentPage: currentPage - 1 }, () => this.searchReceipts())
                                                        }
                                                    }}>
                                                    Zurück
                                                    </Button>
                                                <Form.Text style={{ color: '#0A5699', fontWeight: 'bold' }}>Seite: {this.state.currentPage} / {this.state.maxPages}</Form.Text>
                                                <Button
                                                    variant="primary"
                                                    size="sm"
                                                    disabled={this.state.currentPage === this.state.maxPages}
                                                    onClick={() => {
                                                        const currentPage = this.state.currentPage;
                                                        if (currentPage <= this.state.maxPages) {
                                                            this.setState({ currentPage: currentPage + 1 }, () => this.searchReceipts())
                                                        }
                                                    }}>
                                                    Weiter
                                                </Button>
                                            </div>
                                        </Card.Footer>
                                    }
                                </Card>
                            </>
                        }

                        {this.state.showDeleteModal &&
                            <Modal show={this.state.showDeleteModal}>
                                <Modal.Header>
                                    <Modal.Title>Zuordnung löschen</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <H2>
                                        Wollen Sie die Schadenzuordnung wirklich löschen?
                                    </H2>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" style={{ float: 'left' }}
                                        onClick={() => {
                                            this.setState({ showDeleteModal: false })
                                        }}>Schließen</Button>
                                    <Button variant="danger" style={{ float: 'right' }}
                                        onClick={() => {
                                            this.deleteReceiptRelation(this.state.toDeleteReceiptId!);
                                            this.setState({ showDeleteModal: false });
                                        }}>Löschen</Button>
                                </Modal.Footer>
                            </Modal>
                        }

                        {this.state.showRegulatorModal &&
                            <Modal show={this.state.showRegulatorModal}>
                                <Modal.Header>
                                    <Modal.Title>Regulierer auswählen:</Modal.Title>
                                    <Button variant="secondary" style={{ float: 'left' }}
                                        onClick={() => {
                                            this.setState({ showRegulatorModal: false }, () => this.searchRegulator(undefined));
                                        }}>Schließen</Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Control type="text"
                                            placeholder="Suchen..."
                                            value={this.state.damageNumber}
                                            onChange={(e: any) => {
                                                this.searchRegulator(e.target.value);
                                            }}
                                        />
                                    </Form.Group>
                                    <ListGroup>
                                        {this.state.searchedRegulators && this.state.searchedRegulators.map(c => (
                                            <ListGroupItem action
                                                id={c.guid}
                                                style={{ fontSize: '9pt' }}
                                                onClick={(e: any) => {
                                                    this.switchReceiptRelation(this.state.toSwitchReceiptId!, e.target.id);
                                                }}>
                                                {c.firstName}{" "}{c.lastName}{" ("}{c.userName}{")"}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" style={{ float: 'left' }}
                                        onClick={() => {
                                            this.setState({ showRegulatorModal: false }, () => this.searchRegulator(undefined));
                                        }}>Schließen</Button>
                                </Modal.Footer>
                            </Modal>
                        }
                    </>
                }
            />
        )
    }
}