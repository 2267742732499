import { Client, ReceiptSearchDto, ReceiptDto, AuthenticateRequest, EmailDto, ReportingSearchDto, UserInfo, PersonSearchDto, RatingDto, JwtClientToken } from "./Client";

export class APIClient {
    private readonly client: Client;

    constructor(basePath: string, apiKey: string) {
        this.client = new Client(basePath);
    }

    public login(request: AuthenticateRequest) {
        return this.client.login(request);
    }

    public validate(jwtClientToken: JwtClientToken) {
        return this.client.validate(jwtClientToken);
    }

    public getUser(id: number) {
        return this.client.user(id);
    }

    public getUserByGuid(id: string) {
        return this.client.guid(id);
    }

    public getReport(token: string, id: number, key: string) {
        return this.client.report(token, id, key);
    }

    public getDocument(token: string, receiptId: string, documentId: number) {
        return this.client.document(token, receiptId, documentId);
    }

    public getRatings(token: string, userId: number) {
        return this.client.ratings(token, userId);
    }

    public saveRating(token: string, ratingDto: RatingDto) {
        return this.client.rating(token, ratingDto);
    }

    public getDepartments(token: string, customerId: string) {
        return this.client.departments(token, customerId);
    }

    public getRegulators(token: string, userInfo: UserInfo) {
        return this.client.regulators(token, userInfo);
    }

    public searchRegulator(token: string, searchKey: string, userinfo: UserInfo){
        return this.client.regulators2(token, searchKey, userinfo);
    }

    public setRegulator(token: string, receiptId: string, regulatorId: string) {
        return this.client.setregulator(token, receiptId, regulatorId);
    }

    public getReceipt(token: string, receiptId: string) {
        return this.client.receipt(token, receiptId);
    }

    public getReceiptByGuid(token: string, receiptId: string) {
        return this.client.guid2(token, receiptId);
    }

    public getCommonReceipt(token: string, receiptId: string) {
        return this.client.common(token, receiptId);
    }

    public searchReceipts(token: string, receiptSearch: ReceiptSearchDto) {
        return this.client.search(token, receiptSearch);
    }

    public deleteReceipt(token: string, receiptId: string) {
        return this.client.delete(token, receiptId);
    }

    public saveReceipt(token: string, receiptDto: ReceiptDto) {
        return this.client.save(token, receiptDto);
    }

    public finishReceipt(token: string, receiptDto: ReceiptDto) {
        return this.client.finish(token, receiptDto);
    }

    public sendEmail(token: string, receiptId: string, emailDto: EmailDto) {
        return this.client.mail(token, receiptId, emailDto);
    }

    public resetPassword(userInfo: UserInfo){
        return this.client.resetPassword(userInfo);
    }

    public forgotPassword(authenticateRequest: AuthenticateRequest){
        return this.client.forgotPassword(authenticateRequest)
    }

    public getPersons(token: string, personSearchDto: PersonSearchDto) {
        return this.client.persons(token, personSearchDto);
    }

    public createPerson(token: string, userInfo: UserInfo) {
        return this.client.create(token, userInfo);
    }

    public updatePerson(token: string, userInfo: UserInfo) {
        return this.client.person(token, userInfo);
    }

    public lockPerson(token: string, userInfo: UserInfo) {
        return this.client.lock(token, userInfo);
    }

    public unlockPerson(token: string, userInfo: UserInfo) {
        return this.client.unlock(token, userInfo);
    }

    public setStandinPerson(token: string, userInfo: UserInfo) {
        return this.client.standin(token, userInfo);
    }

    public getReporting(token: string, reportSearchDto: ReportingSearchDto) {
        return this.client.reporting(token, reportSearchDto);
    }

    public assignReceipt(token: string, damageNumber: string, userInfo: UserInfo) {
        return this.client.assign(token, damageNumber, userInfo.id);
    }
}