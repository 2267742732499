import * as React from 'react';
import { Container, Navbar, NavItem, NavLink } from 'react-bootstrap';
import './NavMenu.css';
import { RatingDto, UserInfo } from '../shared/Client';
import { ScreenView } from '../App';
import logo from '../assets/logos/pe.png';
import theme from '../theme/theme';

interface NavMenuState {
}

interface NavMenuProps {
    onNavMenuClick: (navMenu: ScreenView) => void;
    currentUser?: UserInfo;
    ratings?: RatingDto[];
}


export default class NavMenu extends React.PureComponent<NavMenuProps, NavMenuState> {
    constructor(props: NavMenuProps) {
        super(props);

        this.state = {
        }
    }

    onButtonClick = (navMenu: ScreenView) => {
        this.props.onNavMenuClick(navMenu);
    }

    public render() {
        return (
            <header>
                <Navbar
                    fixed="top"
                    className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
                    style={{
                        backgroundColor: theme.colors.secondary,
                        color: theme.colors.text.primary
                    }}>
                    <Container fluid='xl'>
                        <Navbar.Brand href={"/"} style={{ fontSize: '1.25rem' }}>
                            <img alt="" src={logo} style={{ width: '12rem' }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2" />
                        <Navbar.Collapse className="d-sm-inline-flex flex-sm-row-reverse" id="basic-navbar-nav">
                            <ul className="navbar-nav flex-grow" style={{ fontSize: '0.8rem' }}>
                                {this.props.currentUser === null &&
                                    <NavItem>
                                        <NavLink
                                            style={{ color: theme.colors.text.primary }}
                                            href="#" onClick={() => this.onButtonClick(ScreenView.Login)}>
                                            Login
                                        </NavLink>
                                    </NavItem>
                                }
                                {this.props.currentUser && this.props.currentUser !== null &&
                                    <>
                                        {console.log(this.props.currentUser)}
                                        <NavItem>
                                            <NavLink
                                                style={{ color: theme.colors.text.primary }}
                                                href="#" onClick={() => this.onButtonClick(ScreenView.Home)}>
                                                Belegliste
                                                </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ color: theme.colors.text.primary }}
                                                href="#" onClick={() => this.onButtonClick(ScreenView.Rating)}>
                                                Bewertungen ({this.props.ratings?.length})
                                            </NavLink>
                                        </NavItem>
                                        {this.props.currentUser.teamlead &&
                                            <NavItem>
                                                <NavLink
                                                    style={{ color: theme.colors.text.primary }}
                                                    href="#" onClick={() => this.onButtonClick(ScreenView.UserManagement)}>
                                                    Benutzerverwaltung
                                                </NavLink>
                                            </NavItem>
                                        }
                                         {this.props.currentUser && 
                                            !this.props.currentUser.customerList?.contains("9") && 
                                            !this.props.currentUser.customerList?.contains("46") &&
                                            <NavItem>
                                                <NavLink
                                                    style={{ color: theme.colors.text.primary }}
                                                    href="#" onClick={() => this.onButtonClick(ScreenView.Assign)}>
                                                    Schadenzuweisung
                                                </NavLink>
                                            </NavItem>
                                        }
                                        <NavItem>
                                            <NavLink
                                                style={{ color: theme.colors.text.primary }}
                                                href="#" onClick={() => this.onButtonClick(ScreenView.UserSettings)}>
                                                Einstellung
                                            </NavLink>
                                        </NavItem>
                                        {this.props.currentUser.teamlead &&
                                            <NavItem>
                                                <NavLink
                                                    style={{ color: theme.colors.text.primary }}
                                                    href="#" onClick={() => this.onButtonClick(ScreenView.Evaluate)}>
                                                    Auswertung
                                            </NavLink>
                                            </NavItem>
                                        }
                                        <NavItem>
                                            <NavLink
                                                style={{ color: theme.colors.text.primary }}
                                                href="#" onClick={() => { localStorage.removeItem('PropertyExpert.ExpertiseCheck.Token'); this.onButtonClick(ScreenView.Login) }}>
                                                Logout
                                            </NavLink>
                                        </NavItem>
                                    </>
                                }
                            </ul>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
