import * as React from 'react';
import { Form, Alert } from 'react-bootstrap';
import styled from 'styled-components'
import AppConfiguration from '../components/AppConfiguration';
import { AuthenticateRequest, UserInfo } from '../shared/Client';
import { APIClient } from '../shared/ApiClient';
import { Button } from '../components/common/Button';
import { H3 } from '../components/common/H3';
import { FadeIn } from '../components/animations/FadeIn';
import { IMainMessage, VariantEnum } from '../components/MainMessage';

enum LoginView {
    Login,
    Reset,
    InfoView
}

interface LoginState {
    username?: string;
    password?: string;
    error: boolean;
    view: LoginView
}

interface LoginProps {
    onLogin: (currentUser: UserInfo) => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
    currentUser?: UserInfo;
    appConfiguration?: AppConfiguration;
    apiClient?: APIClient;
}

export default class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            error: false,
            view: LoginView.Login
        }
    }

    init = async () => {

    }

    forgotPassword = async () => {
        this.setState({ view: LoginView.Reset });
    }

    changePassword = async () => {
        await this.props.apiClient!.forgotPassword({
            username: this.state.username,
            password: this.state.username
        } as AuthenticateRequest);
        this.setState({ view: LoginView.InfoView });
    }

    backLogin = () => {
        this.setState({ view: LoginView.Login });
    }

    login = () => {
        const username = this.state.username;
        const password = this.state.password;
        if (username && password && password.length > 2 && username.length > 3) {
            let apiClient = this.props.apiClient;
            apiClient!.login({
                username: this.state.username,
                password: this.state.password
            } as AuthenticateRequest)
                .then((jwtClientToken) => {
                    if (jwtClientToken && jwtClientToken!.tokenString) {
                        localStorage.setItem('PropertyExpert.ExpertiseCheck.Token', JSON.stringify(jwtClientToken));
                        this.props.onLogin(jwtClientToken.user!);
                    }
                    else {
                        this.props.editMainMessage(
                            {
                                show: true,
                                variant: VariantEnum.danger,
                                message: 'Zugangsdaten sind falsch!'
                            } as IMainMessage
                        );
                    }
                });
        }
        else {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Bitte Felder ausfüllen!'
                } as IMainMessage
            );
        }
    }

    handleKeyPress = (target: any) => {
        if (target.charCode === 13) {
            this.login();
        }
    }

    render() {
        return (
            <AppContainer>
                <Main>
                    {this.state.view === LoginView.Login &&
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <H3 marginTop="0" marginBottom=".5rem">
                                    Benutzername
                            </H3>
                                <Form.Control type="text" placeholder="Benutzername"
                                    onKeyPress={(e: any) => this.handleKeyPress(e)}
                                    onChange={(e: any) => e.target.value ? this.setState({ username: e.target.value }) : true}
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <H3 marginTop="0" marginBottom=".5rem">
                                    Passwort
                            </H3>
                                <Form.Control type="password" placeholder="Passwort"
                                    onKeyPress={(e: any) => this.handleKeyPress(e)}
                                    onChange={(e: any) => e.target.value ? this.setState({ password: e.target.value }) : true}
                                />
                            </Form.Group>
                            <Form.Group>
                                {this.state.error &&
                                    <Alert className="alert alert-danger fade show" variant='danger'>
                                        <h3> Login fehlgeschlagen!</h3>
                                        <p>Bitte überprüfen Sie Ihre Zugangsdaten!</p>
                                    </Alert>
                                }
                            </Form.Group>
                            <Button
                                kind={VariantEnum.secondary}
                                buttonSize="small"
                                style={{ float: 'left' }}
                                onClick={this.forgotPassword}>
                                Passwort vergessen
                            </Button>
                            <Button
                                kind={VariantEnum.primary}
                                buttonSize="small"
                                style={{ float: 'right' }}
                                onClick={this.login}>
                                Login
                            </Button>
                        </Form>
                    }
                    {this.state.view === LoginView.InfoView &&
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <H3 marginTop="0" marginBottom=".5rem">
                                    Eine E-Mail mit einem Link, um das Passwort zu ändern, wurde an Ihre EMail-Adresse versendet.
                                </H3>
                            </Form.Group>
                            <Button
                                kind={VariantEnum.secondary}
                                buttonSize="small"
                                style={{ float: 'left' }}
                                onClick={() => this.setState({ view: LoginView.Login })}>
                                Zum Login
                            </Button>
                        </Form>
                    }
                    {this.state.view === LoginView.Reset &&
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <H3 marginTop="0" marginBottom=".5rem">
                                    Benutzername
                                </H3>
                                <Form.Control type="text" placeholder="Benutzername"
                                    onKeyPress={(e: any) => this.handleKeyPress(e)}
                                    onChange={(e: any) => e.target.value ? this.setState({ username: e.target.value }) : true}
                                />
                            </Form.Group>
                            <Button
                                kind={VariantEnum.secondary}
                                buttonSize="small"
                                style={{ float: 'left' }}
                                onClick={this.backLogin}>
                                Zurück
                            </Button>
                            <Button
                                kind={VariantEnum.primary}
                                buttonSize="small"
                                style={{ float: 'right' }}
                                onClick={this.changePassword}>
                                Passwort per Email versenden
                            </Button>
                        </Form>
                    }
                </Main>
            </AppContainer>
        )
    }
}

const Main = styled.main`
  text-align: left;
  padding: 1rem 2rem 3rem 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-width: 500px;
  align-self: center;
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  animation: ${FadeIn} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`