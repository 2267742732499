import * as React from 'react';
import { Table, Form, Modal, Card } from 'react-bootstrap';
import { APIClient } from "../shared/ApiClient";
import { UserInfo, RatingDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { Button } from '../components/common/Button';
import { H3 } from '../components/common/H3';
import { H1 } from '../components/common/H1';
import { IMainMessage, VariantEnum } from '../components/MainMessage';
import { StarRating } from '../components/StarRating';

interface RatingState {
    show: boolean;
    loading: boolean;
    ratings?: RatingDto[];
    selectedRating?: RatingDto;
    stars1: number;
    stars2: number;
    stars3: number;
    stars4: number;
    comment?: string;
}

interface RatingProps {
    ratings?: RatingDto[];
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
}

export default class Rating extends React.Component<RatingProps, RatingState> {
    constructor(props: RatingProps) {
        super(props);

        this.state = {
            show: false,
            loading: true,
            stars1: 0,
            stars2: 0,
            stars3: 0,
            stars4: 0
        }
    }

    init = async () => {
        this.setState({ ratings: this.props.ratings, loading: false });
    }

    saveRating = async () => {
        let selectedRating = this.state.selectedRating;
        if (selectedRating) {
            selectedRating.star1 = this.state.stars1;
            selectedRating.star2 = this.state.stars2;
            selectedRating.star3 = this.state.stars3;
            selectedRating.star4 = this.state.stars4;
            selectedRating.comment = this.state.comment;
            selectedRating.uniqueId = this.state.selectedRating!.uniqueId;
            await this.props.client!.saveRating(this.props.currentUser!.token!, selectedRating);
            const ratings = await this.props.client!.getRatings(this.props.currentUser!.token!, this.props.currentUser!.id!);
            this.setState({ ratings, loading: false });
        }
        else {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Bewertung nicht ausgewählt'
                } as IMainMessage
            );
        }
        this.setState({ selectedRating: {} as RatingDto, show: false });
    }
    
    openDocumentViewer = () => {
        console.log(this.state.selectedRating);
        window.open("?documentId=" + this.state.selectedRating!.uniqueId!, '_blank');
    }

    render() {
        return (
            <Loader
                loader={async () => { this.init(); }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        {this.state.loading && <MainLoader message="Ihre Daten werden geladen..." />}

                        {!this.state.loading &&
                            <Card>
                                <Card.Body>
                                    <H1>Bewertungen</H1>
                                    <Table hover bordered style={{ fontSize: 'smaller' }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Schadennummer</th>
                                                <th>Regulierer</th>
                                                <th>Versicherter</th>
                                                <th>Schadenort</th>
                                                <th>Datum</th>
                                                <th>Sanierer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.ratings && this.state.ratings.map((rating) =>
                                                <tr key={rating.receiptId}>
                                                    <th scope="row">
                                                        <H3>
                                                            <Button kind={VariantEnum.secondary} buttonSize="small"
                                                                onClick={() => this.setState({ selectedRating: rating, show: true })}>
                                                                Bewerten
                                                            </Button>
                                                        </H3>
                                                    </th>
                                                    <td><H3>{rating.damageNumber}</H3></td>
                                                    <td><H3>{rating.regulator}</H3></td>
                                                    <td><H3>{rating.claimantFirstName} {rating.claimantLastName}</H3></td>
                                                    <td><H3>{rating.damageStreet}{', '} {rating.damageZipCode} {rating.damageLocation}</H3></td>
                                                    <td><H3>{rating.damageDate}</H3></td>
                                                    <td><H3>{rating.partnerName}</H3></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        }

                        {this.state.selectedRating &&
                            <Modal show={this.state.show} toggle={() => { }} className={"modal-90w"} size="lg">
                                <Modal.Header>
                                    <H1>Bewertung der Schadensbehebung: {this.state.selectedRating!.damageNumber}</H1>
                                </Modal.Header>
                                <Modal.Body>
                                    {(this.props.currentUser.customerList?.contains('31') || this.props.currentUser.customerList?.contains('32')) &&
                                    <>
                                        <Table borderless style={{ fontSize: 'smaller' }}>
                                            <tbody>
                                                <tr>
                                                    <td>Wie beurteilen Sie den zeitlichen Ablauf?</td>
                                                    <td>
                                                        <StarRating onStarRating={(stars: number) => this.setState({ stars1: stars })} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Wie beurteilen Sie die Kommunikation mit dem Sanierer</td>
                                                    <td>
                                                        <StarRating onStarRating={(stars: number) => this.setState({ stars2: stars })} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Wie beurteilen Sie die Prüfdienstleistung von PropertyExpert?</td>
                                                    <td>
                                                        <StarRating onStarRating={(stars: number) => this.setState({ stars3: stars })} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Wie beurteilen Sie den zeitlichen Ablauf (Dauer der Sanierung)?</td>
                                                    <td>
                                                        <StarRating onStarRating={(stars: number) => this.setState({ stars4: stars })} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Table borderless style={{ fontSize: 'smaller' }}>
                                            <tr>
                                                <td>Kam es zu einer Beschwerde durch den VN?</td>
                                            </tr>
                                            <tr>
                                                <td><Form.Control as="textarea" name="text" onChange={(e) => this.setState({comment: e.target.value})} /></td>
                                            </tr>
                                        </Table>
                                    </>
                                    }
                                    {(this.props.currentUser.customerList?.contains('9') || this.props.currentUser.customerList?.contains('46')) &&
                                    <>
                                        <Table borderless style={{ fontSize: 'smaller' }}>
                                        <tbody>
                                            <tr>
                                                <td>Wie zufrieden waren Sie mit der Erreichbarkeit des Sanierers?</td>
                                                <td>
                                                    <StarRating onStarRating={(stars: number) => this.setState({ stars1: stars })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Wie zufrieden waren Sie mit der Arbeitsqualität und der -organisation beim Sanierer?</td>
                                                <td>
                                                    <StarRating onStarRating={(stars: number) => this.setState({ stars2: stars })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Wie beurteilen Sie den Informationsaustausch des Sanierers im Verlauf des Schadens?</td>
                                                <td>
                                                    <StarRating onStarRating={(stars: number) => this.setState({ stars3: stars })} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Wie bewerten Sie Art und Umfang der Angebotserstellung?</td>
                                                <td>
                                                    <StarRating onStarRating={(stars: number) => this.setState({ stars4: stars })} />
                                                </td>
                                            </tr>
                                        </tbody>
                                        </Table>
                                        <Table borderless style={{ fontSize: 'smaller' }}>
                                            <tr>
                                                <td>Kommentar:</td>
                                            </tr>
                                            <tr>
                                                <td><Form.Control as="textarea" name="text" onChange={(e) => this.setState({comment: e.target.value})} /></td>
                                            </tr>
                                        </Table>
                                       </>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button kind={VariantEnum.secondary} buttonSize="small" onClick={this.openDocumentViewer}>Viewer</Button>{' '}
                                    <Button kind={VariantEnum.secondary} buttonSize="small" onClick={() => { this.setState({ show: false }) }}>Abbrechen</Button>
                                    <Button kind={VariantEnum.primary} buttonSize="small" onClick={() => { this.saveRating() }}>Bewertung absenden</Button>
                                </Modal.Footer>
                            </Modal>
                        }
                    </>
                }
            />
        )
    }
}