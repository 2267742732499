import styled from 'styled-components';
import { transparentize } from 'polished';
import { VariantEnum } from '../MainMessage';

export interface ButtonProps {
  kind: VariantEnum;
  disabled?: boolean;
  buttonSize?: string;
}

function  getColor(p: any){
  return p.kind === VariantEnum.primary ? p.theme.colors.text.primary 
        : p.kind === VariantEnum.light ? p.theme.colors.text.light 
        : p.kind === VariantEnum.danger ? p.theme.colors.text.light 
        : p.theme.colors.text.secondary;
}
function  getBackgroundColor(p: any){
  return p.kind === VariantEnum.primary ? p.theme.colors.primary 
        : p.kind === VariantEnum.light ? p.theme.colors.light 
        : p.kind === VariantEnum.danger ? p.theme.colors.danger
        : p.theme.colors.secondary;
}
function  getBorderColor(p: any){
  return p.kind === VariantEnum.primary ? p.theme.colors.text.primary 
        : p.kind === VariantEnum.light ? p.theme.colors.text.light 
        : p.kind === VariantEnum.danger ? p.theme.colors.text.danger 
        : p.theme.colors.text.secondary;
}
function  getDisabledColor(p: any){
  return p.kind === VariantEnum.primary ? p.theme.colors.primary 
        : p.kind ===  VariantEnum.light ? p.theme.colors.light 
        : p.kind === VariantEnum.danger ? p.theme.colors.danger
        : p.theme.colors.secondary
}

export const Button = styled.a<ButtonProps>`
  text-align: center;
  box-shadow: ${p => p.disabled ? "" : `0 2px 5px ${transparentize(0.9, p.theme.colors.text.secondary)}`};
  background-color: ${p => p.disabled ? transparentize(0.5, getBackgroundColor(p)) : getDisabledColor(p)};
  border: ${p => `1px solid ${getBorderColor(p)}`};
  color: ${p => p.disabled ? transparentize(0.5, getColor(p)) : getDisabledColor(p)};
  border-radius: 3rem;
  font-size: ${p => p.buttonSize === "small" ? "0.9rem" : "1rem"};
  padding: ${p => p.buttonSize === "small" ? "0.45rem .9rem" : ".8rem 2rem"};
  text-decoration: none;
  user-select: none;
  background-image: none; 
  transition: background-color ${p => p.theme.transitions.duration} ease-in-out,
              box-shadow ${p => p.theme.transitions.duration} ease-in-out,
              color ${p => p.theme.transitions.duration} ease-in-out,
              transform .1s ease-in-out;
  cursor: ${p => p.disabled ? "default" : "pointer"};
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &:hover {
    box-shadow: ${p => p.disabled ? "" : `0 2px 5px ${transparentize(0.9, p.theme.colors.text.secondary)}`};
    background-color: ${p => p.disabled ? transparentize(0.7, getBackgroundColor(p)) : getDisabledColor(p)};
  }
  &:active {
    box-shadow: ${p => p.disabled ? "" : `0 2px 5px ${transparentize(0.9, p.theme.colors.text.secondary)}`};
    background-color: ${p => p.disabled ? transparentize(0.5, getBackgroundColor(p)) : getDisabledColor(p)};
    transform: ${p => p.disabled ? "" : "scale(0.95)"};
  }
`;