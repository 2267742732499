import * as React from 'react';
import { Form, Col, Row, Badge, InputGroup, Accordion, Card, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { H2 } from '../components/common/H2';
import { H3 } from '../components/common/H3';
import { UserInfo, ReceiptPositionDto, ReceiptDto, DocumentDto } from "../shared/Client";
import { APIClient } from '../shared/ApiClient';
import AppConfiguration from '../components/AppConfiguration';
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { Button } from '../components/common/Button';
import { IMainMessage, VariantEnum } from '../components/MainMessage';
import HeaderView from '../views/HeaderView';
import DragAndDrop from '../components/DragAndDrop';

interface PartnerViewProps {
  editMainMessage: (mainMessageProps: IMainMessage) => void;
  mainMessageProps: IMainMessage;
  client: APIClient;
  appConfiguration: AppConfiguration;
  receiptId: string;
}

interface PartnerViewState {
  loading: boolean;
  documentShow: boolean;
  showAllCases: boolean;
  receipt?: ReceiptDto;
  documents: DocumentDto[];
}

export default class PartnerView extends React.Component<PartnerViewProps, PartnerViewState> {
  constructor(props: PartnerViewProps) {
    super(props);
    this.state = {
      loading: true,
      documentShow: false,
      showAllCases: false,
      documents: []
    };
  }

  componentWillMount() {
  }

  componentWillReceiveProps(newProps: PartnerViewProps) {
  }

  init = async () => {
    const receipt = await this.props.client.getReceipt('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluQHByb3BlcnR5ZXhwZXJ0LmRlIiwidXNlcl9mdWxsbmFtZSI6ImFkbWluQHByb3BlcnR5ZXhwZXJ0LmRlIiwibmJmIjoxNjA3NTk5NTI0LCJleHAiOjE2MDc2MjgzMjQsImlhdCI6MTYwNzU5OTUyNCwiaXNzIjoiUHJvcGVydHlFeHBlcnQuRXhwZXJ0aXNlQ2hlY2siLCJhdWQiOiJodHRwczovL2V4cGVydGlzZS5wcm9wZXJ0eWV4cGVydC5kZSJ9.gGtIFCwqaN9piZJJJXBa0Zb2-uCev0fMo4iitVTk91s',
     this.props.receiptId);
    this.setState({ loading: false, receipt });
  }

  onModalClose = (documentShow: boolean) => {
    this.setState({ documentShow });
  }

  openReport = () => {
    this.setState({ documentShow: true });
    this.props.client!.getReport('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluQHByb3BlcnR5ZXhwZXJ0LmRlIiwidXNlcl9mdWxsbmFtZSI6ImFkbWluQHByb3BlcnR5ZXhwZXJ0LmRlIiwibmJmIjoxNjA3NTk5NTI0LCJleHAiOjE2MDc2MjgzMjQsImlhdCI6MTYwNzU5OTUyNCwiaXNzIjoiUHJvcGVydHlFeHBlcnQuRXhwZXJ0aXNlQ2hlY2siLCJhdWQiOiJodHRwczovL2V4cGVydGlzZS5wcm9wZXJ0eWV4cGVydC5kZSJ9.gGtIFCwqaN9piZJJJXBa0Zb2-uCev0fMo4iitVTk91s', 
    Number(this.state.receipt!.refBelegId), this.state.receipt!.documentKey!)
      .then((document) => {
        var byteCharacters = atob(document.content!);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }

  openDocument = () => {
    this.setState({ documentShow: true })
    const belegId = this.state.receipt!.refBelegId;
    const key = this.state.receipt!.documentKey!;
    window.open(this.props.appConfiguration!.backendConfiguration!.webViewer + '?id=' + belegId + '&key=' + key, '_blank');
  }
  openDocumentViewer = () => {
    window.open("?documentId=" + this.state.receipt!.uniqueId!, '_blank');
  }

  isLoading = (loading: boolean) => {
    this.setState({ loading });
  }

  saveReceipt = async () => {
  }

  onUpload = (documents: DocumentDto[]) => {
    this.setState({ documents });
  }

  updatePosition = (receiptPosition: ReceiptPositionDto, expertiseUnitPrice?: string, expertiseUnitAmount?: string) => {
    const receipt = this.state.receipt;
    if (receipt) {
      const positions = receipt!.receiptPositions;
      if (positions != null) {
        for (var i = 0; i < positions.length; i++) {
          const position = positions[i];
          if (position.id === receiptPosition.id) {
            if (expertiseUnitPrice) {
              expertiseUnitPrice = expertiseUnitPrice.replace(',', '.');
              position.partnerData!.unitPrice = Number(expertiseUnitPrice);
            }
            if (expertiseUnitAmount) {
              expertiseUnitAmount = expertiseUnitAmount.replace(',', '.');
              position.partnerData!.amount = Number(expertiseUnitAmount);
            }
            if (position.unit === "%") {
              debugger;
              position.partnerData!.totalPrice = Math.round((position.partnerData!.unitPrice! * position.partnerData!.amount! / 100) * 100) / 100;
            }
            else {
              debugger;
              position.partnerData!.totalPrice = position.partnerData!.unitPrice! * position.partnerData!.amount!;
            }
          }
        }
        let totalPrice = 0;
        receipt.receiptPositions!.forEach((r) => { totalPrice = totalPrice + r.partnerData!.totalPrice! });
      }
      this.setState({ receipt }, () => this.forceUpdate());
    }
  }

  updateComment = (receiptPosition: ReceiptPositionDto, comment?: string) => {
    debugger;
    const receipt = this.state.receipt;
    if (receipt) {
      const positions = receipt!.receiptPositions;
      if (positions != null) {
        for (var i = 0; i < positions.length; i++) {
          const position = positions[i];
          if (position.id === receiptPosition.id) {
            position.comment = comment;
          }
        }
      }
      this.setState({ receipt }, () => this.forceUpdate());
      console.log(receipt);
    }
  }

  render() {
    let loading = this.state.loading;
    let receipt = this.state.receipt;

    return (
      <Loader
        loader={async () => { this.init() }}
        onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
        onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
        onSuccess={
          <>
            {this.state.receipt &&
              <>
                <Card>
                  <Card.Body>
                    <HeaderView
                      currentUser={{ teamlead: false } as UserInfo}
                      appConfiguration={this.props.appConfiguration}
                      client={this.props.client}
                      receipt={this.state.receipt!}
                      regulators={[]}
                      onLoad={(loading: boolean) => this.isLoading(loading)}
                    />
                  </Card.Body>
                </Card>
                <Card style={{
                  paddingTop: '1rem',
                  marginTop: '1rem',
                }}>
                  <Card.Header>
                    <ToggleButtonGroup type="radio" name="options" defaultValue={"nioPositions"}>
                      <ToggleButton value={"nioPositions"} checked={true}
                        onClick={(e: any) => { this.setState({ showAllCases: false }) }}>
                        n.i.O Positionen
                        </ToggleButton>
                      <ToggleButton value={"allPositions"}
                        onClick={(e: any) => { this.setState({ showAllCases: true }) }}>
                        Alle Positionen
                        </ToggleButton>
                    </ToggleButtonGroup>
                  </Card.Header>
                  {!this.state.showAllCases &&
                    <Card.Body>
                      <Row md={12} style={{ borderBottom: 'solid 0px #000' }}>
                        <Col md={5}>
                          <Form.Group>
                            <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', paddingTop: '0.5rem', color: '#000' }}>
                              Position
                              </H2>
                          </Form.Group>
                        </Col>
                        <Col md={7}>
                          <Row md={12} >
                            <Col md={3}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', paddingTop: '0.5rem', color: '#000' }}>
                                  Einheit
                                  </H2>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', paddingTop: '0.5rem', color: '#000' }}>
                                  Einzelpreis
                                </H2>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', paddingTop: '0.5rem', color: '#000' }}>
                                  Gesamtpreis
                                </H2>
                              </Form.Group>
                            </Col>
                            <Col md={5}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', paddingTop: '0.5rem', color: '#000' }}>
                                  Kommentar
                                </H2>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {receipt
                        && receipt.receiptPositions
                        && receipt.receiptPositions
                          .filter((receiptPosition) => receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice)
                          .map((receiptPosition) =>
                            <Row md={12} key={receiptPosition.id}
                              style={{
                                borderTop: 'solid 1px #000',
                                paddingTop: '1rem',
                                paddingBottom: '1rem',
                                backgroundColor: '#fff',
                                color: '#fff'
                              }}>
                              <Col md={5}>
                                <H3>
                                  <Badge variant="dark" pill
                                    style={{
                                      fontSize: '9pt'
                                    }}>
                                    {receiptPosition.position}
                                  </Badge>
                                  {"  "}{receiptPosition.description}
                                </H3>
                              </Col>
                              <Col md={7}>
                                <Row md={12}>
                                  <Col md={3}>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <InputGroup.Append>
                                        <InputGroup.Text>E</InputGroup.Text>
                                      </InputGroup.Append>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                          }).format(receiptPosition.inputData!.amount!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>{receiptPosition.unit}</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <InputGroup.Append>
                                        <InputGroup.Text>A</InputGroup.Text>
                                      </InputGroup.Append>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                          }).format(receiptPosition.expertiseCheckData!.amount!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>{receiptPosition.unit}</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    {receiptPosition.comment && receiptPosition.comment.length > 0 &&
                                      <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                        <InputGroup.Append>
                                          <InputGroup.Text>V</InputGroup.Text>
                                        </InputGroup.Append>
                                        <Form.Control style={{ textAlign: "right" }}
                                          type="text"
                                          onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                            ? this.updatePosition(receiptPosition, undefined, e.target.value.replace('.', '').replace(',', '.'))
                                            : true}
                                          defaultValue={new Intl.NumberFormat("de-DE",
                                            {
                                              minimumFractionDigits: 3,
                                              maximumFractionDigits: 3
                                            }).format(receiptPosition.partnerData!.amount!)}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text>{receiptPosition.unit}</InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    }
                                  </Col>
                                  <Col md={2}>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.inputData!.unitPrice!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.expertiseCheckData!.unitPrice!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    {receiptPosition.comment && receiptPosition.comment.length > 0 &&
                                      <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                        <Form.Control style={{ textAlign: "right" }}
                                          type="text"
                                          onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                            ? this.updatePosition(receiptPosition, e.target.value.replace('.', '').replace(',', '.'), undefined)
                                            : true}
                                          defaultValue={new Intl.NumberFormat("de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }).format(receiptPosition.partnerData!.unitPrice!)}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text>€</InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    }
                                  </Col>
                                  <Col md={2}>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.inputData!.totalPrice!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                      <Form.Control style={{ textAlign: "right" }}
                                        type="text" disabled={true} readOnly={true}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.expertiseCheckData!.totalPrice!)}
                                      />
                                      <InputGroup.Append>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Append>
                                    </InputGroup>
                                    {receiptPosition.comment && receiptPosition.comment.length > 0 &&
                                      <InputGroup size="sm" style={{ marginTop: '0.25rem' }}>
                                        <Form.Control style={{ textAlign: "right" }}
                                          disabled={true} readOnly={true}
                                          type="text"
                                          value={new Intl.NumberFormat("de-DE",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }).format(receiptPosition.partnerData!.totalPrice!)}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text>€</InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    }
                                  </Col>
                                  <Col md={5}>
                                    <Form.Control
                                      as="select"
                                      name="select"
                                      size="sm"
                                      defaultValue={receiptPosition.comment}
                                      onChange={(e: any) => this.updateComment(receiptPosition, e.target.value)}
                                      style={{ height: '30px', marginTop: '0.25rem' }}  >
                                      <option value={''}></option>
                                      <option value={'nicht notwendig'}>nicht notwendig</option>
                                      <option value={'nicht schadenbedingt (Menge)'}>nicht schadenbedingt (Menge)</option>
                                      <option value={'Preisabweichung'}>Preisabweichung</option>
                                      <option value={'Mehrfachabrechnung'}>Mehrfachabrechnung</option>
                                      <option value={'nicht Versicherungsumfang'}>nicht Versicherungsumfang</option>
                                      <option value={'nicht erforderlich (Qualität)'}>nicht erforderlich (Qualität)</option>
                                      <option value={'Qualitätsabweichung'}>Qualitätsabweichung</option>
                                      <option value={'Menge u. EP'}>Menge u. EP</option>
                                      <option value={'Klärungsbedarf'}>Klärungsbedarf</option>
                                    </Form.Control>
                                    <Form.Control
                                      size="sm"
                                      style={{ marginTop: '0.25rem' }}
                                      disabled={receiptPosition.comment!.length! <= 0}
                                      readOnly={receiptPosition.comment!.length! <= 0}
                                      onBlur={(e: any) => this.updateComment(receiptPosition, e.target.value)}
                                      as="textarea" />
                                    {/*<Form.Control
                                      size="sm"
                                      disabled={true}
                                      readOnly={true}
                                      style={{ marginTop: '0.25rem' }}
                                      defaultValue={receiptPosition.comment}
                                      as="textarea" />
                                    */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                    </Card.Body>
                  }
                  {this.state.showAllCases &&
                    <Card.Body>
                      <Row md={12}
                        style={{
                          backgroundColor: '#fff',
                          color: '#000'
                        }}>
                        <Col md={5}>
                          <Row md={12}>
                            <Col md={2}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', height: '40px', paddingTop: '1rem', color: '#000' }}>
                                  Nr.
                                  </H2>
                              </Form.Group>
                            </Col>
                            <Col md={10}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', height: '40px', paddingTop: '1rem', color: '#000' }}>
                                  Position
                                            </H2>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={7}>
                          <Row style={{ height: '30px' }}>
                            <Col md={1}>
                              <Form.Group>
                              </Form.Group>
                            </Col>
                            <Col md={2}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', height: '40px', float: 'right', color: '#000' }}>Menge</H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', height: '40px', float: 'right', color: '#000' }}>EP </H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', height: '40px', float: 'right', color: '#000' }}>GP</H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row style={{ height: '30px' }}>
                            <Col md={1}>
                              <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', float: 'left', height: '40px', color: '#000' }}>Einheit</H2>
                            </Col>
                            <Col md={2}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', float: 'right', height: '40px', color: '#000' }}>Kalk.Menge</H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', float: 'right', height: '40px', color: '#000' }}>Kalk. EP</H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Form.Group>
                                <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', float: 'right', height: '40px', color: '#000' }}>Kalk. GP</H2>
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <H2 style={{ fontWeight: 'bold', fontSize: '0.8rem', float: 'right', height: '40px', color: '#000' }}>Anmerkung</H2>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {receipt
                        && receipt.receiptPositions
                        && receipt.receiptPositions.map((receiptPosition) =>
                          <Row md={12} key={receiptPosition.id}
                            style={{
                              borderBottom: 'solid 3px #fff',
                              paddingTop: '1rem',
                              paddingBottom: '1rem',
                              backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                '#dc3545' : '#6c757d',
                              color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                '#fff' : '#fff'
                            }}>
                            <Col md={5}>
                              <Row md={12}>
                                <Col md={2}>
                                  <Form.Group>
                                    <Badge variant="dark" pill
                                      style={{
                                        fontSize: '9pt',
                                        backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                          '#fff' : '#fff',
                                        color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                          '#000' : '#000'
                                      }}>
                                      {receiptPosition.position}
                                    </Badge>
                                  </Form.Group>
                                </Col>
                                <Col md={10}>
                                  <H3 style={{
                                    color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ? '#fff' : '#fff'
                                  }}>
                                    {receiptPosition.description}
                                  </H3>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={7}>
                              <Row style={{ paddingBottom: '0rem' }} >
                                <Col md={1}>
                                  <Form.Group>
                                    <Badge variant="dark" pill
                                      style={{
                                        fontSize: '9pt',
                                        backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                          '#fff' : '#fff',
                                        color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                          '#000' : '#000'
                                      }}
                                    >
                                      {receiptPosition.unit}
                                    </Badge>
                                  </Form.Group>
                                </Col>
                                <Col md={2}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control type="text" disabled={true} readOnly={true}
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem' }}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                          }).format(receiptPosition.inputData!.amount!)}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control type="text" disabled={true} readOnly={true}
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem', maxWidth: '100px' }}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.inputData!.unitPrice!)}
                                      />
                                      <InputGroup.Prepend style={{ height: '30px' }}>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control type="text" disabled={true} readOnly={true}
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem', maxWidth: '100px' }}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.inputData!.totalPrice!)}
                                      />
                                      <InputGroup.Prepend style={{ height: '30px' }}>
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group>
                                    <Form.Control as="select" name="select" size="sm"
                                      defaultValue={receiptPosition.comment}
                                      disabled={true} readOnly={true}
                                      onChange={(e: any) => this.updateComment(receiptPosition, e.target.value)}
                                      style={{ height: '30px' }}  >
                                      <option value={''}></option>
                                      <option value={'nicht notwendig'}>nicht notwendig</option>
                                      <option value={'nicht schadenbedingt (Menge)'}>nicht schadenbedingt (Menge)</option>
                                      <option value={'Preisabweichung'}>Preisabweichung</option>
                                      <option value={'Mehrfachabrechnung'}>Mehrfachabrechnung</option>
                                      <option value={'nicht Versicherungsumfang'}>nicht Versicherungsumfang</option>
                                      <option value={'nicht erforderlich (Qualität)'}>nicht erforderlich (Qualität)</option>
                                      <option value={'Qualitätsabweichung'}>Qualitätsabweichung</option>
                                      <option value={'Menge u. EP'}>Menge u. EP</option>
                                      <option value={'Klärungsbedarf'}>Klärungsbedarf</option>
                                    </Form.Control >
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row style={{ height: '30px' }}>
                                <Col md={1}>
                                </Col>
                                <Col md={2}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control
                                        readOnly={true} disabled={true}
                                        type="text"
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem', maxWidth: '100px' }}
                                        onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                          ? this.updatePosition(receiptPosition, undefined, e.target.value.replace('.', '').replace(',', '.'))
                                          : true}
                                        defaultValue={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 3,
                                            maximumFractionDigits: 3
                                          }).format(receiptPosition.expertiseCheckData!.amount!)}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control
                                        readOnly={true} disabled={true}
                                        type="text"
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem', maxWidth: '100px' }}
                                        onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                          ? this.updatePosition(receiptPosition, e.target.value.replace('.', '').replace(',', '.'), undefined)
                                          : true}
                                        defaultValue={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.expertiseCheckData!.unitPrice!)}
                                      />
                                      <InputGroup.Prepend style={{ height: '30px' }} >
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                    <InputGroup size="sm">
                                      <Form.Control type="text" disabled={true} readOnly={true}
                                        style={{ height: '30px', textAlign: "right", marginBottom: '0rem', maxWidth: '100px' }}
                                        value={new Intl.NumberFormat("de-DE",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                          }).format(receiptPosition.expertiseCheckData!.totalPrice!)}
                                      />
                                      <InputGroup.Prepend style={{ height: '30px' }} >
                                        <InputGroup.Text>€</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col md={3}>
                                  <Form.Control
                                    defaultValue={receiptPosition.comment}
                                    readOnly={true}
                                    type="text" placeholder="" size="sm"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                    </Card.Body>
                  }
                </Card>
                {this.state.receipt &&
                  <Card style={{
                    paddingTop: '1rem',
                    marginTop: '1rem',
                  }}>
                    <Card.Body>
                      <Row md={12} style={{
                        paddingBottom: '1rem'
                      }}>
                        <Col md={4}><H2>Ergebnis Info</H2></Col>
                        <Col md={8}><H3>{this.state.receipt!.resultInformation}</H3></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                }
                <Card style={{ marginTop: '1rem' }}>
                  <Card.Body>
                    <Row md={12}>
                      <Col md={12}>
                        <Row md={12}>
                          <Col md={3}>
                            <H2 style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                              Dokumente hochladen
                            </H2>
                          </Col>
                          <Col md={9}>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12}>
                        <DragAndDrop
                          onUpload={(documents: DocumentDto[]) => {
                            this.onUpload(documents);
                          }}
                          documents={this.state.documents} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {!loading &&
                  <>
                    <Row md={12} style={{ marginBottom: '1rem' }}>
                      <Col md={12} style={{ paddingTop: '1rem' }}>
                        <Button kind={VariantEnum.secondary} style={{ float: "left", margin: '0.25rem' }} onClick={this.openReport}>Letzten Prüfbericht anzeigen</Button>
                        <Button kind={VariantEnum.secondary} style={{ float: "left", margin: '0.25rem' }} onClick={this.openDocument}>Dokumente anzeigen</Button>
                        <Button kind={VariantEnum.secondary} style={{ float: "left", margin: '0.25rem' }} onClick={this.openDocumentViewer}>Viewer</Button>
                        <Button kind={VariantEnum.primary} style={{ float: "right", margin: '0.25rem' }} onClick={this.saveReceipt}>Gegenvorschlag senden</Button>
                        <Button kind={VariantEnum.primary} style={{ float: "right", margin: '0.25rem' }} onClick={this.saveReceipt}>PE Ergebnis akzeptieren</Button>
                      </Col>
                    </Row>
                  </>
                }
              </>
            }
          </>
        }
      />
    )
  }
}