import * as React from 'react';
import * as ReactDOM from 'react-dom';

const FirstChild = ({ children }: React.Props<any>) => {
  let kids = React.Children.toArray(children);
  return kids[0] as React.ReactElement<any> || null;
};

export default class Portal extends React.Component {

  portalElement: HTMLDivElement | null;

  constructor(props: any) {
    super(props);
    this.portalElement = null;
  }

  componentDidMount() {
    const p = document.createElement('div');
    document.body.appendChild(p);
    this.portalElement = p;
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    let kids = React.Children.toArray(this.props.children);
    if (this.portalElement) {
      if (kids.length === 0)
        ReactDOM.unmountComponentAtNode(this.portalElement!);
      else
        ReactDOM.render(kids[0] as React.ReactElement<any>, this.portalElement!);
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalElement!);
  }

  render() {
    return null;
  }
}
