import * as React from 'react';
import { Button, Table, Alert } from 'react-bootstrap';
import { APIClient } from '../shared/ApiClient';
import { EmailDto, RatingDto, UserInfo } from '../shared/Client';
import ModalEmailSendView from '../views/ModalEmailSendView';

interface ToDoState {
    ratings: RatingDto[];
    loading: boolean;
    selectedReceiptId?: number;
    emailViewShow: boolean;
}

interface ToDoProps {
    currentUser: UserInfo;
    apiClient?: APIClient;
}

export default class ToDo extends React.Component<ToDoProps, ToDoState> {
    constructor(props: ToDoProps) {
        super(props);

        this.state = {
            ratings: [] as RatingDto[],
            loading: true,
            emailViewShow:false
        }

        this.init();
    }

    init = async () => {
        let currentRatings = await this.props.apiClient!.getRatings(this.props.currentUser!.token!, this.props.currentUser!.id!);
        this.setState({
            ratings: currentRatings,
            loading: false
        });
    }

    onButtonClick = (receiptId: number) => {
        this.setState({ 
            selectedReceiptId: receiptId,
            emailViewShow: true
        });
    }

    onFinishEmailModalClose= (loading: boolean, mail: EmailDto) => {
        this.props.apiClient?.sendEmail(this.props.currentUser!.token!,this.state.selectedReceiptId!.toString(), mail);
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <>
                        Laden...
                    </>
                }
                {!this.state.loading && this.state.ratings &&
                    <>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Vorname</th>
                                    <th>Nachname</th>
                                    <th>Kommentar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.ratings.map((rating: RatingDto) => (
                                    <>
                                        <tr>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    onClick={(e: any) => {
                                                        this.onButtonClick(rating.receiptId!);
                                                    }}>
                                                    Auswählen
                                            </Button>
                                            </td>
                                            <td>
                                                {rating.claimantFirstName}
                                            </td>
                                            <td>
                                                {rating.claimantLastName}
                                            </td>
                                            <td>
                                                {rating.comment}

                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                        {this.state.selectedReceiptId &&
                            <ModalEmailSendView
                                currentUser={this.props.currentUser}
                                onLoading={(loading: boolean) =>  console.log('')}
                                onModalCancel={(loading: boolean) => console.log('')}
                                onModalClose={(loading: boolean, emailModel: EmailDto) => this.onFinishEmailModalClose(loading, emailModel)}
                                show={this.state.emailViewShow}
                            />
                        }
                    </>
                }

            </>
        )
    }
}