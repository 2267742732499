import styled from 'styled-components';

export const H2Font = styled.div`
  color: ${p => p.theme.colors.secondary};
  font-size: 0.9rem;
  font-weight: bold;
`;

export const H2 = styled(H2Font as any) <{
  marginTop?: string,
  marginRight?: string,
  marginBottom?: string,
  marginLeft?: string,
  textAlign?: string,
}>`
  display: block;
  margin-top: ${p => p.marginTop || "1rem"};
  margin-right: ${p => p.marginRight || "0rem"};
  margin-bottom: ${p => p.marginBottom || "1rem"};
  margin-left: ${p => p.marginLeft || "0rem"};
  text-align: ${p => p.textAlign || "left"};
  cursor: default;
`;