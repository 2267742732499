import * as React from 'react';
import styled from 'styled-components';
import { DocumentDto } from '../shared/Client';
import Dropzone from 'react-dropzone'
import { ListGroup, Row, Col, Button } from 'react-bootstrap';
import { H2 } from './common/H2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DragAndDropState {
    documents: DocumentDto[];
    acceptedFiles: File[];
}

interface DragAndDropProps {
    onUpload: (documents: DocumentDto[]) => void;
    documents: DocumentDto[];
}

export default class DragAndDrop extends React.PureComponent<DragAndDropProps, DragAndDropState> {
    constructor(props: DragAndDropProps) {
        super(props);

        this.state = {
            documents: this.props.documents,
            acceptedFiles: []
        }
    }

    getBase64 = (file: File) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(file.name + " wurde hochgeladen!");
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    public render() {
        return (
            <Row md={12}>
                <Col md={12}>
                    <Dropzone onDrop={acceptedFiles => {
                        acceptedFiles.map(file => {
                            const reader = new FileReader()
                            reader.onabort = () => console.log('file reading was aborted')
                            reader.onerror = () => console.log('file reading has failed')
                            reader.onload = () => { }
                            reader.readAsArrayBuffer(file);
                            let content = this.getBase64(file);
                            let document = {
                                content: content,
                                fileName: file.name
                            } as DocumentDto;
                            this.setState({ acceptedFiles: [file, ...this.state.acceptedFiles] });
                            this.setState({ documents: [document, ...this.state.documents] });
                        });
                        this.props.onUpload(this.state.documents);
                    }}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <Container {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag & drop Dokumente, oder wählen Sie die Dokumente aus!</p>
                                </Container>
                            </section>
                        )}
                    </Dropzone>
                </Col>
                <Col md={12} style={{marginTop: "1rem"}}>
                    <ListGroup>
                        {this.state.acceptedFiles.map(file => (
                            <ListGroup.Item variant="light">
                                <Row md={12}>
                                    <Col md={6}>
                                        <H2>
                                            {file.name} - {file.size / 1000} kbytes
                                    </H2>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "right", float: "right" }}>
                                        <Button variant="danger">
                                            <FontAwesomeIcon icon="trash" />
                                        Löschen
                                    </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        );
    }
}

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fff;
  color: #000;
  outline: none;
  transition: border .24s ease-in-out;
`;

