import React from "react";
import styled from "styled-components";
import { IconInvalid } from "../../assets";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { VariantEnum } from "../MainMessage";


interface ModalProps {
  isOpen: boolean;
  closeOnBackdropClick?: boolean;
  header?: React.ReactNode;
  children?: React.ReactChild;
  footer?: React.ReactNode;
  onClose: () => void;
  variant: VariantEnum;
}

export class Modal extends React.Component<ModalProps, {}> {
  body?: HTMLDivElement | null;

  constructor(props: ModalProps) {
    super(props);
  }

  componentWillReceiveProps(newProps: ModalProps) {
    if (!this.props.isOpen && newProps.isOpen && this.body) {
      console.log("ℹ️ disable bodyscroll");
      disableBodyScroll(this.body);
    } else if (this.props.isOpen && !newProps.isOpen && this.body) {
      console.log("ℹ️ enable bodyscroll");
      enableBodyScroll(this.body);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  onBackdropClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (this.props.closeOnBackdropClick) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <>
        <ModalContainer isOpen={this.props.isOpen}>
          <ContentContainer isOpen={this.props.isOpen} 
            style={{backgroundColor: 
                      this.props.variant === VariantEnum.success ? '#d4edda' :
                        this.props.variant === VariantEnum.danger ? '#f5c6cb' 
                          : '#ffffff' }}>
            <StyledIconTime onClick={this.props.onClose} />
            {this.props.header && <Header>{this.props.header}</Header>}
            {this.props.children && <Body ref={r => this.body = r}>{this.props.children}</Body>}
            {this.props.footer && <Footer>{this.props.footer}</Footer>}
          </ContentContainer>
        </ModalContainer>
        <Backdrop isOpen={this.props.isOpen} onClick={this.onBackdropClicked} />
      </>
    );
  }
}

const ModalContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 630px;
  min-width: 320px;
  height: auto;
  z-index: 2000;
  visibility: ${p => (p.isOpen ? "visible" : "hidden")};
  backface-visibility: hidden;
  transform: translateX(-50%) translateY(-50%);
  @media (orientation: portrait) {
    width: 50vw;
  }
  @media (orientation: landscape) {
    width: 80vw;
  }
`;

const ContentContainer = styled.div<{ isOpen: boolean }>`
  padding: 2rem 0rem;
  max-height: 80vh;
  color: #000;
  background-color: #fff;
  position: relative;
  border-radius: 3px;
  margin: 0 auto;
  transform: ${p => (p.isOpen ? "scale(1)" : "scale(0.7)")};
  opacity: ${p => (p.isOpen ? "1" : "0")};
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
`;

const StyledIconTime = styled(IconInvalid)`
  color: ${props => props.theme.colors.secondary};
  height: 2rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  padding: 1rem;
  transition: color 0.1s, transform 0.1s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &:hover {
    color: lighten(0.1, ${p => p.theme.colors.primary});
  }
  &:active {
    color: lighten(0.1, ${p => p.theme.colors.primary});
    transform: scale(0.8);
  }
`;

const Footer = styled.div`
  margin: 0 auto;
  padding: 2rem 2rem 0rem 2rem;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Header = styled.div`
  text-align: left;
  padding: 0rem 4rem 2rem 2rem;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Body = styled.div`
  padding: 0rem 2rem 0rem 2rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 1;
  flex-grow: 1;
`;

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: ${p => (p.isOpen ? "visible" : "hidden")};
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: ${p => (p.isOpen ? "1" : "0")};
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  overflow: hidden;
  touch-action: none;
`;
