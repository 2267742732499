import * as React from 'react';
import { Table, Card, Form, Row, Col, Alert } from 'react-bootstrap';
import { Button } from '../components/common/Button';
import { APIClient } from "../shared/ApiClient";
import { UserInfo } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { IMainMessage, VariantEnum } from '../components/MainMessage';

enum UserView {
    ChangePassword,
    Detail
}

interface UserSettingsState {
    loading: boolean;
    persons?: UserInfo[];
    view: UserView;
    variant?: string;
    messageSuccess?: string;
    userId?: number;
    department?: string;
    teamLead: boolean;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    street?: string;
    zipcode?: string;
    city?: string;
    phone1?: string;
    phone2?: string;
    oldPassword?: string;
    password?: string;
    password2?: string;
}

interface UserSettingsProps {
    onClose: () => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
}

export default class UserSettings extends React.Component<UserSettingsProps, UserSettingsState> {
    constructor(props: UserSettingsProps) {
        super(props);

        this.state = {
            loading: true,
            view: UserView.Detail,
            teamLead: false,
            userId: this.props.currentUser!.id,
            firstName: this.props.currentUser!.firstName,
            lastName: this.props.currentUser!.lastName,
            userName: this.props.currentUser!.username,
            email: this.props.currentUser!.eMail,
            street: this.props.currentUser!.street,
            zipcode: this.props.currentUser!.zipCode,
            city: this.props.currentUser!.city,
            phone1: this.props.currentUser!.phone1,
            phone2: this.props.currentUser!.phone2
        }
    }

    init = async () => {
        this.setState({ loading: false });
    }

    saveUser = async () => {
        await this.props.client!.updatePerson(this.props.currentUser!.token!, 
            {
                id: this.state.userId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                username: this.state.userName,
                eMail: this.state.email,
                street: this.state.street,
                zipCode: this.state.zipcode,
                city: this.state.city,
                phone1: this.state.phone1,
                phone2: this.state.phone2,
            } as UserInfo);

        this.props.editMainMessage(
            {
                show: true,
                variant: VariantEnum.success,
                message: 'Benutzer erfolgreich gespeichert!'
            } as IMainMessage
        );
        this.setState({ loading: false });
    }

    saveUserPassword = async () => {
        if (!this.state.password || !this.state.password2) {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Bitte Felder ausfüllen!'
                } as IMainMessage
            );

        }
        else {
            if (this.state.password!.length < 6 || this.state.password2!.length < 6) {
                this.props.editMainMessage(
                    {
                        show: true,
                        variant: VariantEnum.danger,
                        message: 'Das Passwort muss mindestens 6 Zeichen umfassen.'
                    } as IMainMessage
                );
            }
            else
            {
                if (this.state.password === this.state.password2) {
                    await this.props.client!.updatePerson(this.props.currentUser!.token!, 
                        {
                            id: this.state.userId,
                            password: this.state.password
                        } as UserInfo);
    
                    this.props.editMainMessage(
                        {
                            show: true,
                            variant: VariantEnum.success,
                            message: 'Passwort erfolgreich gespeichert!'
                        } as IMainMessage
                    );
                }
                else {
                    this.props.editMainMessage(
                        {
                            show: true,
                            variant: VariantEnum.danger,
                            message: 'Passwörter sind nicht identisch!'
                        } as IMainMessage
                    );
                }
            }
        }
    }

    render() {
        return (
            <Loader
                loader={async () => { this.init(); }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        {this.state.loading && <MainLoader message="Ihre Daten werden geladen..." />}

                        {!this.state.loading &&
                            <>
                                {this.state.view === UserView.Detail &&
                                    <Card>
                                        <Card.Header>
                                            <h5>Benutzer</h5>
                                            <Button kind={VariantEnum.primary} 
                                                    style={{ float: 'right' }} buttonSize="small" 
                                                    onClick={() => { this.setState({ view: UserView.ChangePassword }) }}>
                                                Passwort ändern
                                            </Button>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Vorname</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.firstName}
                                                                onChange={(e: any) => {
                                                                    this.setState({ firstName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Nachname</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.lastName}
                                                                onChange={(e: any) => {
                                                                    this.setState({ lastName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Benutzername (E-Mail)</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.userName}
                                                                onChange={(e: any) => {
                                                                    this.setState({ userName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>E-Mail</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.email}
                                                                onChange={(e: any) => {
                                                                    this.setState({ email: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Straße</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.street}
                                                                onChange={(e: any) => {
                                                                    this.setState({ street: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>PLZ</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.zipcode}
                                                                onChange={(e: any) => {
                                                                    this.setState({ zipcode: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Ort</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.city}
                                                                onChange={(e: any) => {
                                                                    this.setState({ city: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Telefon 1</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.phone1}
                                                                onChange={(e: any) => {
                                                                    this.setState({ phone1: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Telefon 2</Form.Label>
                                                            <Form.Control type="text"
                                                                value={this.state.phone2}
                                                                onChange={(e: any) => {
                                                                    this.setState({ phone2: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button kind={VariantEnum.secondary} style={{ float: 'left' }} buttonSize="small" onClick={() => { this.props.onClose() }}>
                                                    Zurück
                                                </Button>
                                                <Button kind={VariantEnum.primary} style={{ float: 'right' }} buttonSize="small" onClick={this.saveUser}>Speichern</Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                }
                                {this.state.view === UserView.ChangePassword &&
                                    <Card>
                                        <Card.Header>
                                            <h5>Passwort ändern</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Neues Passwort*</Form.Label>
                                                            <Form.Control type="password" placeholder="******"
                                                                onChange={(e: any) => {
                                                                    this.setState({ password: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Neues Passwort wiederholen*</Form.Label>
                                                            <Form.Control type="password" placeholder="******"
                                                                onChange={(e: any) => {
                                                                    this.setState({ password2: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button kind={VariantEnum.secondary} style={{ float: 'left' }} buttonSize="small" 
                                                        onClick={() => { this.setState({ view: UserView.Detail }) }}>
                                                    Zurück
                                                </Button>
                                                <Button kind={VariantEnum.primary} style={{ float: 'right' }} buttonSize="small" 
                                                        onClick={this.saveUserPassword}>
                                                    Speichern
                                                </Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                }
                            </>
                        }
                    </>
                }
            />
        )
    }
}