import * as React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { H3 } from '../components/common/H3';
import { H2 } from '../components/common/H2';
import { APIClient } from "../shared/ApiClient";
import { UserInfo } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { Button } from '../components/common/Button';
import { IMainMessage, VariantEnum } from '../components/MainMessage';

interface AssignState {
    loading: boolean;
    damageNumber?: string;
    damageNumber1?: string;
    damageNumber2?: string;
    damageNumber3?: string;
    damageNumber4?: string;
    damageNumber5?: string;
    sucess?: boolean;
    errorMessage?: string;
}

interface AssignProps {
    onClose: () => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
    receiptId: string;
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
}

export default class Assign extends React.Component<AssignProps, AssignState> {
    constructor(props: AssignProps) {
        super(props);

        this.state = {
            loading: true,
            damageNumber1: undefined,
            damageNumber2: undefined,
            damageNumber3: undefined,
            damageNumber4: undefined,
            damageNumber5: undefined
        }
    }

    init = async () => {
        console.log(this.props.currentUser);
        this.setState({ loading: false });
    }

    isLoading = (loading: boolean) => {
        this.setState({ loading });
    }

    assignReceipt = async () => {
        let separator = "";
        
        if(this.props.currentUser && this.props.currentUser.customerList?.contains("194")) // DEVK
            separator = '.';
        if(this.props.currentUser && this.props.currentUser.customerList?.contains("32")) // ALTE LEIPZIGER
            separator = '-';

        let damageNumber = [this.state.damageNumber1, this.state.damageNumber2, this.state.damageNumber3, this.state.damageNumber4, this.state.damageNumber5]
                           .filter(function (val) { return val; })
                           .join(separator);

        if(damageNumber.length <= 0)
        {
            this.setState({ 
                loading: false,
                sucess: false,
                errorMessage:'Bitte die Schadennummer eingeben!',
                damageNumber1: undefined,
                damageNumber2: undefined,
                damageNumber3: undefined,
                damageNumber4: undefined,
                damageNumber5: undefined
            });
        }
        else
        {
            //WUW Formatieren
            if(this.props.currentUser.customerList?.contains("65"))
            {
                if(damageNumber.length == 11)
                {
                  const d1 =  damageNumber.slice(0,2);
                  const d2 =  damageNumber.slice(2,9);
                  const d3 =  damageNumber.slice(9,11);
                  
                  damageNumber = d1 + '-'+ d2 + '-' + d3;
                }
                else{
                    this.setState({ 
                        loading: false,
                        sucess: false,
                        errorMessage:'Bitte die Schadennummer eingeben!',
                        damageNumber1: undefined,
                        damageNumber2: undefined,
                        damageNumber3: undefined,
                        damageNumber4: undefined,
                        damageNumber5: undefined
                    });
                }
            }

            let result = await this.props.client!.assignReceipt(this.props.currentUser.token!, damageNumber, this.props.currentUser);
            if(result)
            {
                this.setState({ 
                    loading: false,
                    sucess: true,
                    damageNumber1: undefined,
                    damageNumber2: undefined,
                    damageNumber3: undefined,
                    damageNumber4: undefined,
                    damageNumber5: undefined
                });
            }
            else {
                this.setState({ 
                    loading: false,
                    sucess: false,
                    errorMessage:'Die Schadennummer existiert bereits und kann nicht angelegt werden!',
                    damageNumber1: undefined,
                    damageNumber2: undefined,
                    damageNumber3: undefined,
                    damageNumber4: undefined,
                    damageNumber5: undefined
                });
            }
        }
    }

    render() {
        let loading = this.state.loading;
        return (
            <Loader
                loader={async () => { this.init() }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        {loading && <MainLoader message="Ihre Daten werden geladen..." />}

                        {!loading &&
                        <>
                            <Card>
                                <Card.Header>
                                    <H2>Schadenzuweisung</H2>
                                </Card.Header>
                                <Card.Body>
                                    <H3>Bitte geben Sie die Schadennummer ein:</H3><br/>

                                    {this.props.currentUser && // ERGO
                                        (this.props.currentUser.customerList?.contains("9")  || this.props.currentUser.customerList?.contains("46")) &&
                                        <div className="input-group" style={{width: '100%'}}>
                                            <input type="text" className="form-control" style={{width: '15%'}} disabled={true} value="ASS/SS/HS/KS" />
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-" />
                                            <input type="text" 
                                                className="form-control int-9" 
                                                defaultValue={this.state.damageNumber1}
                                                onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                                style={{width: '30%', textAlign: 'right'}} 
                                                maxLength={9} placeholder="VNR" id="vnr"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-" />
                                            <input type="text" className="form-control int-4" style={{width: '20%', textAlign: 'right'}} 
                                                defaultValue={this.state.damageNumber2}
                                                onChange={(e:any) => {this.setState({damageNumber2: e.target.value})}} 
                                                maxLength={4} 
                                                placeholder="LFD-Nr." 
                                                id="lfn"/>
                                        </div>
                                    }
                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("32") && //Alte Leipziger
                                        <div className="input-group" style={{width: '100%'}}>
                                            <input type="text" 
                                                className="form-control autotab" 
                                                defaultValue={this.state.damageNumber1}
                                                onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                                style={{width: '4%'}} 
                                                maxLength={2} 
                                                placeholder="01"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" 
                                                className="form-control autotab2 int-9" 
                                                defaultValue={this.state.damageNumber2}
                                                onChange={(e:any) => {this.setState({damageNumber2: e.target.value})}} 
                                                style={{width: '10%', textAlign: 'right'}} maxLength={3} placeholder="001"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" 
                                                className="form-control autotab3 int-9" 
                                                defaultValue={this.state.damageNumber3}
                                                onChange={(e:any) => {this.setState({damageNumber3: e.target.value})}} 
                                                style={{width: '10%', textAlign: 'right'}} maxLength={6} placeholder="000001"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" className="form-control autotab4" 
                                                defaultValue={this.state.damageNumber4}
                                                onChange={(e:any) => {this.setState({damageNumber4: e.target.value})}} 
                                                style={{width: '6%'}} maxLength={4} placeholder="0001"/>
                                        </div>
                                    }

                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("6") && // MANNHEIMER
                                        <div className="input-group" style={{width: '100%'}}>
                                            <input type="text" className="form-control js-mv-jahr" style={{width: '5%'}} placeholder="2022" />
                                            <input type="text" className="form-control" style={{width: '6%'}} placeholder="000123" />
                                        </div>
                                    }

                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("162") && // TEST
                                        <div className="input-group" style={{width: '100%'}}>
                                               <input type="text" className="form-control" 
                                               defaultValue={this.state.damageNumber1}
                                               onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                               style={{width: '15%'}} placeholder="0123456789" />
                                        </div>
                                    }

                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("194") && // DEVK
                                        <div className="input-group" style={{width: '100%'}}>
                                               <input type="text" 
                                                className="form-control autotab" 
                                                defaultValue={this.state.damageNumber1}
                                                onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                                style={{width: '4%'}} 
                                                maxLength={4} 
                                                placeholder="0000"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="."/>
                                            
                                            <input type="text" 
                                                className="form-control autotab2 int-9" 
                                                defaultValue={this.state.damageNumber2}
                                                onChange={(e:any) => {this.setState({damageNumber2: e.target.value})}} 
                                                style={{width: '10%', textAlign: 'right'}} 
                                                maxLength={2} 
                                                placeholder="00"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="."/>
                                            
                                            <input type="text" 
                                                className="form-control autotab3 int-9" 
                                                defaultValue={this.state.damageNumber3}
                                                onChange={(e:any) => {this.setState({damageNumber3: e.target.value})}} 
                                                style={{width: '10%'}} 
                                                maxLength={2} placeholder="00"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="."/>
                                            
                                            <input type="text" 
                                                className="form-control autotab4" 
                                                defaultValue={this.state.damageNumber4}
                                                onChange={(e:any) => {this.setState({damageNumber4: e.target.value})}} 
                                                style={{width: '6%'}} 
                                                maxLength={5} 
                                                placeholder="00000"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="."/>

                                            <input type="text" 
                                                className="form-control autotab4" 
                                                defaultValue={this.state.damageNumber5}
                                                onChange={(e:any) => {this.setState({damageNumber5: e.target.value})}} 
                                                style={{width: '6%'}} 
                                                maxLength={1} 
                                                placeholder="0"/>
                                        </div>
                                    }

                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("65") && // Württembergische Versicherung AG
                                        <div className="input-group" style={{width: '100%'}}>
                                            <input type="text" className="form-control autotab" style={{width: '4%'}} 
                                                defaultValue={this.state.damageNumber1}
                                                onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                                maxLength={11} placeholder="00000000000" />
                                        </div>
                                    }

                                    {this.props.currentUser && this.props.currentUser.customerList?.contains("51") && // Helvetia
                                        <div className="input-group" style={{width: '100%'}}>
                                            <input type="text" className="form-control autotab" style={{width: '4%'}} 
                                                defaultValue={this.state.damageNumber1}
                                                onChange={(e:any) => {this.setState({damageNumber1: e.target.value})}} 
                                                maxLength={3} placeholder="120" />
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-" />
                                            <input type="text" className="form-control autotab2" style={{width: '4%', textAlign: 'right'}}
                                                defaultValue={this.state.damageNumber2}
                                                onChange={(e:any) => {this.setState({damageNumber2: e.target.value})}} 
                                                maxLength={1} placeholder="5" />
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" className="form-control autotab3" style={{width: '4%'}} 
                                                defaultValue={this.state.damageNumber3}
                                                onChange={(e:any) => {this.setState({damageNumber3: e.target.value})}} 
                                                maxLength={2} placeholder="19"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" className="form-control autotab4" style={{width: '10%'}} 
                                                defaultValue={this.state.damageNumber4}
                                                onChange={(e:any) => {this.setState({damageNumber4: e.target.value})}} 
                                                maxLength={5} placeholder="12345"/>
                                            <input type="text" className="form-control" style={{width: '4%', textAlign: 'center'}} disabled={true} value="-"/>
                                            <input type="text" className="form-control autotab5" style={{width: '4%', textAlign: 'right'}} 
                                                defaultValue={this.state.damageNumber5}
                                                onChange={(e:any) => {this.setState({damageNumber5: e.target.value})}} 
                                                maxLength={1} placeholder="5"/>
                                        </div>
                                    }
                                <br/>
                                    <Button
                                        kind={VariantEnum.primary}
                                        buttonSize="large"
                                        style={{ float: 'right' }}
                                        onClick={() => {this.assignReceipt()}}>
                                        Schaden zuweisen
                                    </Button>
                                <br/>
                                </Card.Body>
                            </Card>
                             {this.state.sucess === false &&
                                <Alert variant="danger" >
                                    <Alert.Heading>Fehler!</Alert.Heading>
                                    <p>
                                        {this.state.errorMessage}
                                    </p>
                                </Alert>
                            }
                            {this.state.sucess === true &&
                                <Alert variant="success">
                                    <Alert.Heading>Erfolgreich!</Alert.Heading>
                                    <p>
                                        Der Schaden wurde erfolgreich zugewiesen
                                    </p>
                                </Alert>
                            }
                            </>
                        }
                    </>
                }
            />
        )
    }
}