const theme = {
  colors: {
    primary: '#0A5699',
    secondary: '#003A5E',
    success: '#26de81',
    info: '#42DCCB',
    warning: '#D29C49',
    danger: '#dc3545',
    light: '#ffffff',
    background: '#ffffff',
    text: {
      primary: 'rgba(255, 255, 255, 0.9)',
      secondary: 'rgba(0, 0, 0, 0.9)',
      light: '#000000'
    },
  },
  transitions: {
    duration: '0.25s',
  },
  font: {
    size: '12px',
  },
};

export default theme;
