import * as React from 'react';
import { Table, Card, Row, Col, Button, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import { APIClient } from "../shared/ApiClient";
import { UserInfo, ReportingSearchDto, ReportingDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { DateTimePicker } from 'react-widgets';
import { H2 } from '../components/common/H2';
import { IMainMessage } from '../components/MainMessage';

interface EvaluateState {
    loading: boolean;
    reportingSearchDto: ReportingSearchDto;
    reporting: ReportingDto[];
    dateFrom: Date;
    dateUntil: Date;
    selectedGroupName?: string;
}

interface EvaluateProps {
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
}

export default class Evaluate extends React.Component<EvaluateProps, EvaluateState> {
    constructor(props: EvaluateProps) {
        super(props);

        let currentDate = moment();
        let lastDate = moment().startOf("month").add(-1, "month");

        moment.locale('de-DE');

        this.state = {
            loading: true,
            dateUntil: currentDate.toDate(),
            dateFrom: lastDate.toDate(),
            reportingSearchDto: {} as ReportingSearchDto,
            reporting: []
        }
    }

    init = async () => {
        this.refresh();
    }

    refresh = async () => {
        this.setState({ loading: true });
        const reporting = await this.props.client.getReporting(this.props.currentUser!.token!, {
            customerId: this.props.currentUser.customerIds,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateUntil
        } as ReportingSearchDto);
        let firstGroupName = reporting[0];
        this.setState({ reporting, loading: false, selectedGroupName: firstGroupName!.groupName });
    }


    render() {
        let currentDate = moment();
        return (
            <Loader
                loader={async () => { this.init(); }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        {this.state.loading &&
                            <MainLoader message="Ihre Daten werden geladen..." />
                        }
                        {!this.state.loading &&
                            <Card>
                                <Card.Header>
                                    <H2>Auswertung</H2>
                                    <Row md={12}>
                                        <Col md={5}>
                                            <DateTimePicker
                                                onChange={(date) => this.setState({ dateFrom: moment(date).toDate() })}
                                                time={false}
                                                culture={"de-DE"}
                                                defaultValue={this.state.dateFrom}
                                                max={currentDate.toDate()}
                                                defaultCurrentDate={this.state.dateFrom}
                                                placeholder={"Datum von..."} />
                                        </Col>
                                        <Col md={5}>
                                            <DateTimePicker
                                                onChange={(date) => this.setState({ dateUntil: moment(date).toDate() })}
                                                time={false}
                                                culture={"de-DE"}
                                                defaultValue={this.state.dateUntil}
                                                max={currentDate.toDate()}
                                                defaultCurrentDate={this.state.dateUntil}
                                                placeholder={"Datum bis..."} />
                                        </Col>
                                        <Col md={2}>
                                            <Button variant="primary" onClick={() => this.refresh()} size="sm">Aktualisieren</Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {this.state.reporting &&
                                        <>
                                            <ToggleButtonGroup type="radio" name="options" defaultValue={this.state.selectedGroupName}>
                                                {this.state.reporting.map((report) => (
                                                    <ToggleButton value={report.groupName}
                                                        onClick={(e: any) => { this.setState({ selectedGroupName: report.groupName }) }}>
                                                        {report.groupName}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                            {this.state.selectedGroupName &&
                                                this.state.reporting.filter(r => r.groupName === this.state.selectedGroupName).map((report) => (
                                                    <Row md={12} style={{paddingTop:'1rem'}}>
                                                        <Table hover striped bordered style={{ fontSize: 'smaller' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Partner-Name</th>
                                                                    <th>Belege</th>
                                                                    <th>Durchlaufzeit</th>
                                                                    <th>Beleghöhe</th>
                                                                    <th>Einsparung P€</th>
                                                                    <th>Einsparung Regulierer</th>
                                                                    <th>Rechnungssumme</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {report.partner!.map((partner) => (
                                                                    <tr key={partner.name}>
                                                                        <td>{partner.name}</td>
                                                                        <td>{partner.claimCount}</td>
                                                                        <td>{partner.averageFlowTime}</td>
                                                                        <td>{partner.damageAmount?.toFixed(2)} €</td>
                                                                        <td>{(partner.damageAmount! - partner.reductionProperty!).toFixed(2)} €</td>
                                                                        <td>{(partner.damageAmount! - partner.reductionRegulator!).toFixed(2)} €</td>
                                                                        <td>{partner.invoiceAmount?.toFixed(2)} €</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                        <Table hover striped bordered style={{ fontSize: 'smaller' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Mitarbeiter-Name</th>
                                                                    <th>Belege</th>
                                                                    <th>Durchlaufzeit</th>
                                                                    <th>Beleghöhe</th>
                                                                    <th>Einsparung P€</th>
                                                                    <th>Einsparung Regulierer</th>
                                                                    <th>Rechnungssumme</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {report.employees!.map((partner) => (
                                                                    <tr key={partner.name}>
                                                                        <td>{partner.name}</td>
                                                                        <td>{partner.claimCount}</td>
                                                                        <td>{partner.averageFlowTime}</td>
                                                                        <td>{partner.damageAmount?.toFixed(2)} €</td>
                                                                        <td>{(partner.damageAmount! - partner.reductionProperty!).toFixed(2)} €</td>
                                                                        <td>{(partner.damageAmount! - partner.reductionRegulator!).toFixed(2)} €</td>
                                                                        <td>{partner.invoiceAmount?.toFixed(2)} €</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Row>
                                                ))}
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        }
                    </>
                }
            />
        )
    }
}