import * as React from 'react';
import { ListGroup, ListGroupItem, Badge, Form, Row, Col } from 'react-bootstrap';
import { APIClient } from "../shared/ApiClient";
import { UserInfo, ReceiptDto, RegulatorDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { H2 } from '../components/common/H2';
import { Button } from '../components/common/Button';
import { VariantEnum } from '../components/MainMessage';

interface HeaderViewState {
    receipt: ReceiptDto;
    selectedRegulatorId?: string;
    showAdditionalInfo: boolean;
}

interface HeaderViewProps {
    onLoad: (loading: boolean) => void;
    receipt: ReceiptDto;
    client: APIClient;
    regulators: RegulatorDto[];
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
}

export default class HeaderView extends React.Component<HeaderViewProps, HeaderViewState> {
    constructor(props: HeaderViewProps) {
        super(props);

        this.state = {
            receipt: this.props.receipt,
            showAdditionalInfo: false
        }
    }

    init = async () => {
    }

    setRegulator = async () => {
        this.props.onLoad(true);
        const client = this.props.client;
        const receipt = this.props.receipt;
        if (client && receipt && this.state.selectedRegulatorId !== "0") {
            await client.setRegulator(this.props.currentUser!.token!, receipt.uniqueId!, this.state.selectedRegulatorId!);
            this.props.onLoad(false);
        }
    }

    deleteReceipt = async () => {
        this.props.onLoad(true);
        const client = this.props.client;
        const receipt = this.state.receipt;
        if (client && receipt) {
            await client.deleteReceipt(this.props.currentUser!.token!, receipt.uniqueId!);
            window.location.href = this.props.appConfiguration.backendConfiguration!.basePath + "/";
        }
    }

    openAdditionalInfo = () => {
        this.setState({ showAdditionalInfo: !this.state.showAdditionalInfo });
    }

    render() {
        let receipt = this.props.receipt;
        return (
            <Row md={12}
                style={{
                    border: '0px solid rgba(0, 0, 0, 0.125)',
                    borderRadius: '0.25rem',
                    marginTop: '1rem',
                    backgroundColor: '#fff'
                }}>
                <Col md={3}>
                    <h3><Badge variant="dark">{receipt!.documentType} vom {receipt!.receiptDate}</Badge></h3>
                </Col>
                <Col md={3}>
                    <h3><Badge variant="dark">Einstelldatum am {receipt!.finishText}</Badge></h3>
                </Col>
                <Col md={3}>
                    <h3><Badge variant="danger">Partner: {receipt!.craftsman}</Badge></h3>
                </Col>
                <Col md={3}>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label><H2>Schadennummer</H2></Form.Label>
                        <Form.Control type="text" size="sm" readOnly={true} value={receipt.damageNumber} />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label><H2>Versicherter</H2></Form.Label>
                        <Form.Control type="text" size="sm" readOnly={true} value={receipt.claimant} />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label><H2>Belegstatus</H2></Form.Label>
                        <Form.Control type="text" size="sm" readOnly={true} value={receipt.state} />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group>
                        <Form.Label><H2>Belegdatum</H2></Form.Label>
                        <Form.Control type="text" size="sm" readOnly={true} value={receipt.receiptDate} />
                    </Form.Group>
                </Col>
                <Col md={12}>
                    <Button kind={VariantEnum.primary} buttonSize="small" onClick={() => this.openAdditionalInfo()}>
                        Weitere Informationen ein-/ausblenden
                    </Button>
                </Col>
                <Col md={12}>
                    {this.state.showAdditionalInfo &&
                        <ListGroup style={{ fontSize: '0.85rem', paddingTop: '1rem' }}>
                            <ListGroupItem>
                                <Row md={12}>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>Regulierer</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.reviewerName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>PE Telefonnummer</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.propertyExpertData!.phoneNumber} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>PE Kontaktperson</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.propertyExpertData!.contactPerson} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>PE Auftragsnummer</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.propertyExpertData!.orderNumber} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row md={12}>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>Schadenursache</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.damageData!.damageCause} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>Partner</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.partner} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>Partnernummer</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.partnerNumber} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label><H2>Schadenart</H2></Form.Label>
                                            <Form.Control type="text" size="sm" readOnly={true} value={receipt.damageData!.damageType} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                            {this.props.currentUser!.teamlead &&
                                <ListGroupItem>
                                    <Row md={12}>
                                        <Col md={1}>
                                            <Form.Group>
                                                <Form.Label><H2>Regulierer:</H2></Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group style={{ marginTop: '1rem' }}>
                                                <Form.Control as="select" size="sm"
                                                    onChange={(e: any) => {
                                                        this.setState({ selectedRegulatorId: e.target.value });
                                                    }}>
                                                    <option value={"0"}>Alle</option>
                                                    {this.props.regulators.map(c => (
                                                        <option value={c.guid}>{c.userName}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={1} style={{ marginTop: '1rem' }} >
                                            <Button style={{ marginTop: '1rem' }} kind={VariantEnum.secondary} onClick={this.setRegulator} buttonSize="small">Zuweisen</Button>
                                        </Col>
                                        <Col md={1} style={{ textAlign: 'left', float: 'left', marginTop: '1rem' }}>
                                            <Button style={{ marginTop: '1rem' }} kind={VariantEnum.danger} onClick={this.deleteReceipt}>Beleg löschen</Button>{' '}
                                        </Col>
                                        <Col md={7}>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            }
                        </ListGroup>
                    }
                </Col>
            </Row >
        )
    }
}