import * as React from 'react';
import { Form, Col, Row, Badge, Spinner,Modal, InputGroup, Card } from 'react-bootstrap';
import { Button } from '../components/common/Button';
import { APIClient } from "../shared/ApiClient";
import { ReceiptDto, RegulatorDto, ReceiptPositionDto, UserInfo, DocumentDto, EmailDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import HeaderView from '../views/HeaderView';
import AdditionalInfoView from '../views/AdditionalInfoView';
import ModalDocumentView from '../views/ModalDocumentView';
import ModalFinishView from '../views/ModalFinishView';
import ModalEmailSendView from '../views/ModalEmailSendView';
import { H2 } from '../components/common/H2';
import { H3 } from '../components/common/H3';
import { IMainMessage, VariantEnum } from '../components/MainMessage';

interface DetailState {
    loading: boolean;
    totalInputPrice: number;
    documentShow: boolean;
    finishViewShow: boolean;
    emailViewShow: boolean;
    dropdownOpen: boolean;
    receipt?: ReceiptDto;
    document?: DocumentDto;
    regulators: RegulatorDto[];
    selectedRegulatorId?: string;
    finished: boolean;
    mainMessageProps: IMainMessage;
    showDeleteModal: boolean;
}

interface DetailProps {
    onClose: () => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
    receiptId: string;
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
}

export default class Detail extends React.Component<DetailProps, DetailState> {
    constructor(props: DetailProps) {
        super(props);

        this.state = {
            totalInputPrice:  0,
            dropdownOpen: false,
            loading: true,
            documentShow: false,
            finishViewShow: false,
            emailViewShow: false,
            showDeleteModal: false,
            regulators: [],
            mainMessageProps: { show: false } as IMainMessage,
            finished: false
        }
    }

    init = async () => {
        this.loadReceipt();
    }

    loadReceipt = async () => {
        let receiptId = this.props.receiptId;
        const receipt = await this.props.client.getReceipt(this.props.currentUser!.token!, receiptId);
        if(receipt && receipt.totalExpertPrice)
        {
            this.setState( {totalInputPrice : receipt.totalExpertPrice});
        }
        const regulators = await this.props.client.getRegulators(this.props.currentUser!.token!, this.props.currentUser!);
        this.setState({ receipt, regulators, loading: false, finished: receipt!.state === 'Abgeschlossen' });
    }

    sendEmailViewShow = () => {
        this.setState({ emailViewShow: true });
    }

    openReport = () => {
        this.setState({ documentShow: true, document: undefined });
        this.props.client!.getReport(this.props.currentUser!.token!, Number(this.state.receipt!.refBelegId), this.state.receipt!.documentKey!)
            .then((document) => {
                var byteCharacters = atob(document.content!);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    openDocument = () => {
        this.setState({ documentShow: true, document: undefined })
        const belegId = this.state.receipt!.refBelegId;
        const key = this.state.receipt!.documentKey!;
        window.open(this.props.appConfiguration!.backendConfiguration!.webViewer + '?id=' + belegId + '&key=' + key, '_blank');
    }

    openDocumentViewer = () => {
        window.open("?documentId=" + this.state.receipt!.uniqueId!, '_blank');
    }

    openPartnerUrl = () => {
        window.open("?receiptId=" + this.state.receipt!.uniqueId!, '_blank');
    }

    saveReceipt = async () => {
        this.setState({ loading: true });
        const client = this.props.client;
        const receipt = this.state.receipt;

        
        console.log(receipt)

        let correctedTotalPrice=0;
        receipt?.receiptPositions?.forEach(each => {
            if(each.expertiseCheckData && each.expertiseCheckData?.totalPrice)
            {
                correctedTotalPrice += each.expertiseCheckData?.totalPrice;
            }
        });
        console.log(this.state.totalInputPrice);
        console.log(correctedTotalPrice);

    if(this.state.totalInputPrice && correctedTotalPrice && (correctedTotalPrice - this.state.totalInputPrice) > 5)
    {
        debugger;
        this.setState({ loading: false });
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Beleg konnte nicht gespeichert: Die Regulierungsempfehlung sollte den Eingangswert nicht überschreiten!'
                } as IMainMessage
            );
    }
    else    {
        if (client && receipt) {
            await client.saveReceipt(this.props.currentUser!.token!, receipt!);

            this.setState({ loading: false });
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.success,
                    message: 'Beleg wurde erfolgreich gespeichert!'
                } as IMainMessage
            );
        }
    }
    }

    closeReceipt = async () => {
        this.setState({ loading: true });
        const client = this.props.client;
        const receipt = this.state.receipt;
        if (client && receipt) {
            await client.finishReceipt(this.props.currentUser!.token!, receipt!);
            this.props.onClose();
        }
    }

    updateComment = (receiptPosition: ReceiptPositionDto, comment?: string) => {
        const receipt = this.state.receipt;
        if (receipt) {
            const positions = receipt!.receiptPositions;
            if (positions != null) {
                for (var i = 0; i < positions.length; i++) {
                    const position = positions[i];
                    if (position.id === receiptPosition.id) {
                        position.comment = comment;
                    }
                }
            }
            this.setState({ receipt }, () => this.forceUpdate());
        }
    }

    deleteAllPositions = () =>{
        const receipt = this.state.receipt;
        if (receipt) {
            const positions = receipt!.receiptPositions;
            if (positions != null) 
            {
                for (var i = 0; i < positions.length; i++) {
                    const position = positions[i];
                    if(position && position.expertiseCheckData)
                    {
                        position.expertiseCheckData!.amount = 0;
                        position.expertiseCheckData!.totalPrice = 0;
                    }
                }
                console.log(this.state.receipt?.receiptPositions);
                this.saveReceipt();
                this.loadReceipt();
                this.setState({ showDeleteModal: false });
            }
        }
    }
   

    updatePosition = (receiptPosition: ReceiptPositionDto, expertiseUnitPrice?: string, expertiseUnitAmount?: string) => {
        const receipt = this.state.receipt;
        if (receipt) {
            const positions = receipt!.receiptPositions;
            if (positions != null) {
                for (var i = 0; i < positions.length; i++) {
                    const position = positions[i];
                    if (position.id === receiptPosition.id) {
                        if (expertiseUnitPrice) {
                            expertiseUnitPrice = expertiseUnitPrice.replace(',', '.');
                            position.expertiseCheckData!.unitPrice = Number(expertiseUnitPrice);
                        }
                        if (expertiseUnitAmount) {
                            expertiseUnitAmount = expertiseUnitAmount.replace(',', '.');
                            position.expertiseCheckData!.amount = Number(expertiseUnitAmount);
                        }
                        if (position.unit === "%") {
                            position.expertiseCheckData!.totalPrice = Math.round((position.expertiseCheckData!.unitPrice! * position.expertiseCheckData!.amount! / 100) * 100) / 100;
                        }
                        else {
                            position.expertiseCheckData!.totalPrice = position.expertiseCheckData!.unitPrice! * position.expertiseCheckData!.amount!;
                        }
                    }
                }
                let totalPrice = 0;
                receipt.receiptPositions!.forEach((r) => { totalPrice = totalPrice + r.expertiseCheckData!.totalPrice! });
                receipt.totalExpertPrice = totalPrice;
                receipt.differancePrice = receipt.totalExpertPrice! - receipt.totalInputPrice!;
            }
            this.setState({ receipt }, () => this.forceUpdate());
        }
    }

    onToggle = () => {
        let o = this.state.dropdownOpen;
        this.setState({ dropdownOpen: !o });
    }

    isLoading = (loading: boolean) => {
        this.setState({ loading });
    }

    onModalClose = (documentShow: boolean) => {
        this.setState({ documentShow });
    }

    openFinishModal = () => {
        this.setState({ finishViewShow: true });
    }

    onFinishModalClose = (finishViewShow: boolean) => {
        this.setState({ finishViewShow });
        this.closeReceipt();
    }

    onCancelModalClose = (finishViewShow: boolean) => {
        this.setState({ finishViewShow });
    }

    onFinishEmailModalClose = async (emailViewShow: boolean, emailDto: EmailDto) => {
        const client = this.props.client;
        if (client) {
            var result = await client.sendEmail(this.props.currentUser!.token!, this.state.receipt!.uniqueId!, emailDto);
            if (result) {
                this.setState({ emailViewShow });
            }
        }
    }

    onCancelEmailModalClose = (emailViewShow: boolean) => {
        this.setState({ emailViewShow });
    }

    render() {
        let receipt = this.state.receipt;
        let loading = this.state.loading;
        return (
            <Loader
                loader={async () => { this.init() }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        <>
                            {loading && <MainLoader message="Ihre Daten werden geladen..." />}

                            {!loading &&
                                <>
                                    <Card>
                                        <Card.Body>
                                            <Row md={12} style={{ marginTop:'1rem', minHeight: '40px', height: '50px' }}>
                                                <Col md={12} style={{ paddingTop: '1rem', paddingBottom: '2rem', textAlign: 'right' }}>
                                                    <Button kind={VariantEnum.secondary} buttonSize="small" onClick={this.props.onClose}>Zurück zur Vorgangsliste</Button>
                                                </Col>
                                            </Row>
                                            <HeaderView
                                                currentUser={this.props.currentUser}
                                                appConfiguration={this.props.appConfiguration}
                                                client={this.props.client}
                                                receipt={this.state.receipt!}
                                                regulators={this.state.regulators}
                                                onLoad={(loading: boolean) => this.isLoading(loading)}
                                            />
                                            <Row md={12}
                                                style={{
                                                    borderTop: '1px solid rgba(0, 0, 0, 0.125)',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                                                    marginTop: '2rem',
                                                    paddingBottom: '1rem',
                                                    marginBottom: '1rem',
                                                    backgroundColor: '#fff',
                                                    color: '#000'
                                                }}>
                                                <Col md={5}>
                                                    <Row md={12}>
                                                        <Col md={2}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', height: '40px', paddingTop: '1rem', color: '#000' }}>
                                                                    Nr.
                                                                </H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={10}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', height: '40px', paddingTop: '1rem', color: '#000' }}>
                                                                    Position
                                                                </H2>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={7}>
                                                    <Row style={{ height: '30px' }}>
                                                        <Col md={1}>
                                                            <Form.Group>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', height: '40px', float: 'right', color: '#000' }}>Menge</H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', height: '40px', float: 'right', color: '#000' }}>EP </H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', height: '40px', float: 'right', color: '#000' }}>GP</H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ height: '30px' }}>
                                                        <Col md={1}>
                                                            <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', float: 'left', height: '40px', color: '#000' }}>Einheit</H2>
                                                        </Col>
                                                        <Col md={2}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', float: 'right', height: '40px', color: '#000' }}>Kalk.Menge</H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', float: 'right', height: '40px', color: '#000' }}>Kalk. EP</H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', float: 'right', height: '40px', color: '#000' }}>Kalk. GP</H2>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <H2 style={{ fontWeight: 'bolder', fontSize: '1rem', float: 'right', height: '40px', color: '#000' }}>Anmerkung</H2>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {receipt && receipt.receiptPositions && receipt.receiptPositions.map((receiptPosition) =>
                                                <Row md={12} key={receiptPosition.id}
                                                    style={{
                                                        borderBottom: 'solid 3px #fff',
                                                        paddingTop: '1rem',
                                                        paddingBottom: '1rem',
                                                        backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                            '#dc3545' : '#6c757d',
                                                        color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                            '#fff' : '#fff'
                                                    }}>
                                                    <Col md={5}>
                                                        <Row md={12}>
                                                            <Col md={2}>
                                                                <Form.Group>
                                                                    <Badge variant="dark" pill
                                                                        style={{
                                                                            fontSize: '9pt',
                                                                            backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                                                '#fff' : '#fff',
                                                                            color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                                                '#000' : '#000'
                                                                        }}>
                                                                        {receiptPosition.position}
                                                                    </Badge>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={10}>
                                                                <H3 style={{
                                                                    color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ? '#fff' : '#fff'
                                                                }}>
                                                                    {receiptPosition.description}
                                                                </H3>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Row style={{ paddingBottom: '0.5rem' }} >
                                                            <Col md={1}>
                                                                <Form.Group>
                                                                    <Badge variant="dark" pill
                                                                        style={{
                                                                            fontSize: '9pt',
                                                                            backgroundColor: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                                                '#fff' : '#fff',
                                                                            color: receiptPosition.expertiseCheckData!.totalPrice !== receiptPosition.inputData!.totalPrice ?
                                                                                '#000' : '#000'
                                                                        }}
                                                                    >
                                                                        {receiptPosition.unit}
                                                                    </Badge>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control type="text" disabled={true} readOnly={true}
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem' }}
                                                                            value={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 3,
                                                                                    maximumFractionDigits: 3
                                                                                }).format(receiptPosition.inputData!.amount!)}
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control type="text" disabled={true} readOnly={true}
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem', maxWidth: '100px' }}
                                                                            value={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(receiptPosition.inputData!.unitPrice!)}
                                                                        />
                                                                        <InputGroup.Prepend style={{ height: '30px' }}>
                                                                            <InputGroup.Text>€</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control type="text" disabled={true} readOnly={true}
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem', maxWidth: '100px' }}
                                                                            value={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(receiptPosition.inputData!.totalPrice!)}
                                                                        />
                                                                        <InputGroup.Prepend style={{ height: '30px' }}>
                                                                            <InputGroup.Text>€</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group>
                                                                    <Form.Control as="select" name="select" size="sm"
                                                                        defaultValue={receiptPosition.comment}
                                                                        disabled={this.state.finished}
                                                                        onChange={(e: any) => this.updateComment(receiptPosition, e.target.value)}
                                                                        style={{ height: '30px' }}  >
                                                                        <option value={''}></option>
                                                                        <option value={'nicht notwendig'}>nicht notwendig</option>
                                                                        <option value={'nicht schadenbedingt (Menge)'}>nicht schadenbedingt (Menge)</option>
                                                                        <option value={'Preisabweichung'}>Preisabweichung</option>
                                                                        <option value={'Mehrfachabrechnung'}>Mehrfachabrechnung</option>
                                                                        <option value={'nicht Versicherungsumfang'}>nicht Versicherungsumfang</option>
                                                                        <option value={'nicht erforderlich (Qualität)'}>nicht erforderlich (Qualität)</option>
                                                                        <option value={'Qualitätsabweichung'}>Qualitätsabweichung</option>
                                                                        <option value={'Menge u. EP'}>Menge u. EP</option>
                                                                        <option value={'Klärungsbedarf'}>Klärungsbedarf</option>
                                                                        <option value={'Abweichung zum LV'}>Abweichung zum LV</option>
                                                                    </Form.Control >
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ height: '30px' }}>
                                                            <Col md={1}>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control
                                                                            disabled={this.state.finished}
                                                                            type="text"
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem', maxWidth: '100px' }}
                                                                            onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                                                                ? this.updatePosition(receiptPosition, undefined, e.target.value.replace('.', '').replace(',', '.'))
                                                                                : true}
                                                                            defaultValue={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 3,
                                                                                    maximumFractionDigits: 3
                                                                                }).format(receiptPosition.expertiseCheckData!.amount!)}
                                                                        />
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control
                                                                            disabled={this.state.finished}
                                                                            type="text"
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem', maxWidth: '100px' }}
                                                                            onChange={(e: any) => e.target.value.replace('.', '').replace(',', '.')
                                                                                ? this.updatePosition(receiptPosition, e.target.value.replace('.', '').replace(',', '.'), undefined)
                                                                                : true}
                                                                            defaultValue={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(receiptPosition.expertiseCheckData!.unitPrice!)}
                                                                        />
                                                                        <InputGroup.Prepend style={{ height: '30px' }} >
                                                                            <InputGroup.Text>€</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                <Form.Group style={{ float: "right", marginBottom: '0rem' }}>
                                                                    <InputGroup size="sm">
                                                                        <Form.Control type="text" disabled={true} readOnly={true}
                                                                            style={{ height: '30px', textAlign: "right", marginBottom: '0.25rem', maxWidth: '100px' }}
                                                                            value={new Intl.NumberFormat("de-DE",
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                }).format(receiptPosition.expertiseCheckData!.totalPrice!)}
                                                                        />
                                                                        <InputGroup.Prepend style={{ height: '30px' }} >
                                                                            <InputGroup.Text>€</InputGroup.Text>
                                                                        </InputGroup.Prepend>
                                                                    </InputGroup>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={3}>
                                                                {receiptPosition.comment &&
                                                                    <Form.Control
                                                                        disabled={receiptPosition.comment!.length! <= 0}
                                                                        readOnly={receiptPosition.comment!.length! <= 0}
                                                                        defaultValue={receiptPosition.comment}
                                                                        onBlur={(e: any) => this.updateComment(receiptPosition, e.target.value)}
                                                                        type="text" placeholder="" size="sm"
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row md={12}
                                                style={{
                                                    borderTop: '1px solid rgba(0, 0, 0, 0.125)',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                                                    paddingTop: '1rem',
                                                    marginTop: '0.5rem',
                                                    paddingBottom: '1rem',
                                                    backgroundColor: '#fff',
                                                    color: '#000'
                                                }}>
                                                <Col md={5}>
                                                    <Form.Group>
                                                        <H2 style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Total</H2>
                                                    </Form.Group>
                                                    <Form.Group style={{ fontSize: '0.9rem' }}>
                                                        <H3 style={{ fontSize: '0.9rem' }}>Alle Beträge werden als netto Werte ausgewiesen.</H3>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={7}>
                                                    <Row md={12}>
                                                        <Col md={12}>
                                                            <Form.Group style={{ float: "right", fontWeight: 'bold', textAlign: "right" }}>
                                                                <Badge variant="dark" pill
                                                                    style={{
                                                                        fontSize: '1.2rem',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                    {new Intl.NumberFormat("de-DE",
                                                                        {
                                                                            style: "currency",
                                                                            currency: "EUR",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(receipt!.totalInputPrice!)}
                                                                </Badge>
                                                                <br />
                                                                <Badge variant="danger" pill
                                                                    style={{
                                                                        marginTop: '1rem',
                                                                        fontSize: '1.2rem',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                    {new Intl.NumberFormat("de-DE",
                                                                        {
                                                                            style: "currency",
                                                                            currency: "EUR",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(receipt!.differancePrice!)}
                                                                </Badge>
                                                                <br />
                                                                <Badge variant="dark" pill
                                                                    style={{
                                                                        marginTop: '1rem',
                                                                        fontSize: '1.2rem',
                                                                        fontWeight: 'bold'
                                                                    }}>
                                                                    {new Intl.NumberFormat("de-DE",
                                                                        {
                                                                            style: "currency",
                                                                            currency: "EUR",
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }).format(receipt!.totalExpertPrice!)}
                                                                </Badge>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <AdditionalInfoView
                                                appConfiguration={this.props.appConfiguration!}
                                                client={this.props.client}
                                                receipt={this.state.receipt!}
                                                onLoad={(loading: boolean) => this.isLoading(loading)}
                                                onChange={(comment: string) => {
                                                    debugger;
                                                        const receipt = this.state.receipt;
                                                        if(receipt){
                                                        receipt.comment = comment;
                                                        this.setState({ receipt }, () => this.forceUpdate());
                                                    }}}
                                                    onChecked={(checked:boolean) => {
                                                        const receipt = this.state.receipt;
                                                        if(receipt){
                                                        receipt.nichtGeprueft = checked;
                                                        this.setState({ receipt }, () => this.forceUpdate());
                                                    }}}
                                            />
                                            {loading &&
                                                <Row>
                                                    <Col md={12}>
                                                        <Button kind={VariantEnum.primary} disabled>
                                                            <Spinner as="span" size="sm" animation="grow" role="status" aria-hidden="true" />{' '}Laden...
                                                </Button>
                                                    </Col>
                                                </Row>
                                            }
                                            {!loading &&
                                                <>
                                                    <Row md={12} style={{ marginTop:'1rem', minHeight: '40px', height: '50px' }}>
                                                        <Col md={6} style={{ paddingTop: '1rem', paddingBottom: '2rem', textAlign: 'left' }}>
                                                            <Button kind={VariantEnum.secondary} buttonSize="small" onClick={this.props.onClose}>Zurück zur Vorgangsliste</Button>
                                                            {' '}
                                                            <Button kind={VariantEnum.secondary} buttonSize="small" onClick={this.openDocument}>Eingangsdokumente</Button>{' '}
                                                            {' '}
                                                            <Button kind={VariantEnum.secondary} buttonSize="small" onClick={this.openDocumentViewer}>Viewer</Button>
                                                        </Col>
                                                        <Col md={6} style={{ paddingTop: '1rem', paddingBottom: '2rem', textAlign: 'right' }}>
                                                            {!this.state.finished &&
                                                                <>
                                                                    {' '}
                                                                    <Button kind={VariantEnum.danger} onClick={()=>this.setState({ showDeleteModal: true })}>Positionen auf Null setzen</Button>
                                                                    {' '}
                                                                    <Button kind={VariantEnum.primary} onClick={this.saveReceipt}>Beleg speichern</Button>
                                                                    {' '}
                                                                    <Button kind={VariantEnum.primary} onClick={this.openFinishModal}>Prüfbericht senden</Button>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </Card.Body>
                                    </Card>

                                    {this.state.document &&
                                        <ModalDocumentView
                                            onLoading={(loading: boolean) => this.isLoading(loading)}
                                            onModalClose={(loading: boolean) => this.onModalClose(loading)}
                                            content={this.state.document!.content!}
                                            show={this.state.documentShow}
                                        />
                                    }

                                    {this.state.showDeleteModal &&
                                        <Modal show={this.state.showDeleteModal}>
                                            <Modal.Body>
                                                <H2>
                                                    Wollen Sie die Positionen auf Null setzen?
                                                </H2>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button kind={VariantEnum.primary} style={{ float: 'left' }}
                                                    onClick={() => {
                                                        this.setState({ showDeleteModal: false })
                                                    }}>Nein</Button>
                                                <Button kind={VariantEnum.danger} style={{ float: 'right' }}
                                                    onClick={this.deleteAllPositions}>
                                                        Ja</Button>
                                            </Modal.Footer>
                                        </Modal>
                                    }
                                    <ModalFinishView
                                        onLoading={(loading: boolean) => this.isLoading(loading)}
                                        onModalCancel={(loading: boolean) => this.onCancelModalClose(loading)}
                                        onModalClose={(loading: boolean) => this.onFinishModalClose(loading)}
                                        show={this.state.finishViewShow}
                                        showCheckBox={this.state.receipt!.totalExpertPrice! > 10000}
                                        outputMail={this.state.receipt!.outputMail!}
                                    />

                                    <ModalEmailSendView
                                        currentUser= {this.props.currentUser}
                                        onLoading={(loading: boolean) => this.isLoading(loading)}
                                        onModalCancel={(loading: boolean) => this.onCancelEmailModalClose(loading)}
                                        onModalClose={(loading: boolean, emailModel: EmailDto) => this.onFinishEmailModalClose(loading, emailModel)}
                                        show={this.state.emailViewShow}
                                    />
                                </>
                            }
                        </>
                    </>
                }
            />
        )
    }
}