import styled from 'styled-components';
import React from 'react';
import { Bounce } from '../animations/Bounce';

interface DotStyle {
  size: string;
  margin: string;
  color?: string;
}

interface SpinnerProps {
  dotStyle: DotStyle;
}

export class Spinner extends React.Component<SpinnerProps, {}> {
  render() {
    return (
      <Wrapper>
        <Dot delay={-0.32} {...this.props.dotStyle} />
        <Dot delay={-0.16} {...this.props.dotStyle} />
        <Dot delay={0} {...this.props.dotStyle} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div<{ delay: number; size: string; margin: string; color?: string }>`
  width: ${p => p.size};
  height: ${p => p.size};
  &:not(:last-child) {
    margin-right: ${p => p.margin};
  }
  background-color: ${p => p.color || '#333'};
  border-radius: 100%;
  animation: ${Bounce} 1.4s infinite ease-in-out ${p => `${p.delay}s`} both;
`;
