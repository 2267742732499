import * as React from 'react';
import { Table, Card, Form, Row, Col } from 'react-bootstrap';
import { APIClient } from "../shared/ApiClient";
import { DepartmentDto, UserInfo, PersonSearchDto } from '../shared/Client';
import AppConfiguration from '../components/AppConfiguration'
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { Button } from '../components/common/Button';
import { H2 } from '../components/common/H2';
import UserSettings from './UserSettings';
import { IMainMessage, VariantEnum } from '../components/MainMessage';


enum UserView {
    Create,
    List,
    Edit,
}

interface UserManagementState {
    loading: boolean;
    persons?: UserInfo[];
    editUser?: UserInfo;
    view: UserView;
    searchKey?: string;
    departments: DepartmentDto[];
    department?: string;
    teamLead: boolean;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    street?: string;
    zipcode?: string;
    city?: string;
    phone1?: string;
    phone2?: string;
    password?: string;
    password2?: string;
}

interface UserManagementProps {
    client: APIClient;
    appConfiguration: AppConfiguration;
    currentUser: UserInfo;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    mainMessageProps: IMainMessage;
}

export default class UserManagement extends React.Component<UserManagementProps, UserManagementState> {
    constructor(props: UserManagementProps) {
        super(props);

        this.state = {
            loading: true,
            teamLead: false,
            view: UserView.List,
            departments: []
        }
    }

    init = async () => {
        const departments = await this.props.client!.getDepartments(this.props.currentUser!.token! ,this.props.currentUser!.customerIds!);
        this.setState({ departments });
        this.searchPersons();
    }

    searchPersons = async () => {
        const persons = await this.props.client!.getPersons(this.props.currentUser!.token!, 
            {
                customerId: this.props.currentUser.customerIds!,
                searchKey: this.state.searchKey!
            } as PersonSearchDto);
        this.setState({ persons, loading: false });
    }

    openPerson = async (personId: number) => {
        let editUser = await this.props.client.getUser(personId);
        if (editUser)
            this.setState({ editUser, view: UserView.Edit });
    }

    createUser = async () => {
        if (this.state.password && this.state.userName) {
            await this.props.client!.createPerson(this.props.currentUser!.token!, {
                city: this.state.city,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                eMail: this.state.email,
                zipCode: this.state.zipcode,
                street: this.state.street,
                password: this.state.password,
                teamlead: this.state.teamLead,
                phone1: this.state.phone1,
                phone2: this.state.phone2,
                department: this.state.department,
                customerId: this.props.currentUser.customerIds
            } as UserInfo);

            this.setState({ view: UserView.List });

            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.success,
                    message: 'Beleg wurde erfolgreich erstellt!'
                } as IMainMessage
            );
        }
        else {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Bitte Daten ausfüllen!'
                } as IMainMessage
            );
        }

    }

    checkPassword = () => {
        if (this.state.password && this.state.password2) {
            if (this.state.password.length > 0 && this.state.password2?.length > 0) {
                if (this.state.password !== this.state.password2) {

                }
            }
        }
    }

    render() {
        return (
            <Loader
                loader={async () => { this.init(); }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        {this.state.loading && <MainLoader message="Ihre Daten werden geladen..." />}

                        {!this.state.loading &&
                            <>
                                {this.state.view === UserView.List &&
                                    <Card>
                                        <Card.Header>
                                            <h5>Benutzer</h5>
                                            <Button
                                                kind={VariantEnum.primary} style={{ float: 'right' }} buttonSize="small"
                                                onClick={() => { this.setState({ view: UserView.Create }) }}>Neuen Benutzer anlegen</Button>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form.Group>
                                                <Form.Text>Benutzer</Form.Text>
                                                <Form.Control
                                                    type="text" size="sm" onChange={(e: any) => {
                                                        if (e.target.value.length > 0)
                                                            this.setState({ searchKey: e.target.value }, () => this.searchPersons());
                                                        else
                                                            this.setState({ searchKey: "" }, () => this.searchPersons());
                                                    }}
                                                />
                                            </Form.Group>

                                            <Table hover bordered style={{ fontSize: 'smaller' }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Vorname</th>
                                                        <th>Name</th>
                                                        <th>Gruppe</th>
                                                        <th>Gr. Leiter</th>
                                                        <th>Gesperrt</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.persons && this.state.persons.map((person) =>
                                                        <tr key={person.id}>
                                                            <td>
                                                                <Button kind={VariantEnum.secondary} buttonSize="small"
                                                                    onClick={() => this.openPerson(person.id!)}>
                                                                    Editieren
                                                                </Button>
                                                            </td>
                                                            <td>{person.firstName}</td>
                                                            <td>{person.lastName}</td>
                                                            <td>{person.department}</td>
                                                            <td>{person.teamlead === true ? 'Ja' : 'Nein'}</td>
                                                            <td>
                                                                <Form>
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id={person.id?.toString()}
                                                                        label={''}
                                                                        value={person.id!}
                                                                        defaultChecked={person.locked}
                                                                        onChange={async (e:any) =>  {
                                                                            await this.props.client!.unlockPerson(this.props.currentUser!.token!, {
                                                                                id: Number(e.target.value!),
                                                                                locked: e.target.checked
                                                                            } as UserInfo);
                                                                        }}
                                                                    />
                                                                </Form>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                }
                                {this.state.view === UserView.Create &&
                                    <Card>
                                        <Card.Header>
                                            <H2>Neuer Benutzer</H2>
                                        </Card.Header>
                                        <Card.Body>
                                            <Form>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Gruppe</Form.Label>
                                                            <Form.Control as="select" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ department: e.target.value });
                                                                }}>
                                                                <option value="">Alle</option>
                                                                {this.state.departments.map(c => (
                                                                    <option value={c.name}>{c.name}</option>
                                                                ))}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Label></Form.Label>
                                                        <Form.Group>
                                                            <Form.Check type="checkbox" label="Gruppenleiter"
                                                                onChange={(e: any) => {
                                                                    this.setState({ teamLead: e.target.checked });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Vorname*</Form.Label>
                                                            <Form.Control type="text" placeholder="Vorname" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ firstName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Nachname*</Form.Label>
                                                            <Form.Control type="text" placeholder="Nachname" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ lastName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Benutzername (E-Mail)*</Form.Label>
                                                            <Form.Control type="text" placeholder="Benutzername" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ userName: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>E-Mail*</Form.Label>
                                                            <Form.Control type="text" placeholder="E-Mail" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ email: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Straße</Form.Label>
                                                            <Form.Control type="text" placeholder="Straße" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ street: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>PLZ</Form.Label>
                                                            <Form.Control type="text" placeholder="PLZ" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ zipcode: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Ort</Form.Label>
                                                            <Form.Control type="text" placeholder="Ort" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ city: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Telefon 1</Form.Label>
                                                            <Form.Control type="text" placeholder="Telefon 1" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ phone1: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Telefon 2</Form.Label>
                                                            <Form.Control type="text" placeholder="Telefon 2" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ phone2: e.target.value });
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Passwort*</Form.Label>
                                                            <Form.Control type="password" placeholder="*******" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ password: e.target.value }, () => this.checkPassword());
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Passwort wiederholen*</Form.Label>
                                                            <Form.Control type="password" placeholder="*******" size="sm"
                                                                onChange={(e: any) => {
                                                                    this.setState({ password2: e.target.value }, () => this.checkPassword());
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button kind={VariantEnum.secondary} style={{ float: 'left' }} buttonSize="small"
                                                    onClick={() => { this.setState({ view: UserView.List }) }}>
                                                    Zurück
                                                </Button>
                                                <Button kind={VariantEnum.primary} style={{ float: 'right' }} buttonSize="small" onClick={this.createUser}>
                                                    Registrieren
                                                </Button>
                                            </Form>
                                        </Card.Body>
                                    </Card>
                                }
                                {this.state.view === UserView.Edit && this.state.editUser &&
                                    <UserSettings
                                        onClose={() => this.setState({ view: UserView.List })}
                                        currentUser={this.state.editUser!}
                                        client={this.props.client!}
                                        appConfiguration={this.props.appConfiguration!}
                                        mainMessageProps={this.props.mainMessageProps}
                                        editMainMessage={(mainMessageProps: IMainMessage) => this.props.editMainMessage(mainMessageProps)}
                                    />
                                }
                            </>
                        }
                    </>
                }
            />
        )
    }
}