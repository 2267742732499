export interface AppParameters {
    receiptId?: string | null,
    documentId?: string | null,
    userId?: string | null
}

export const getAppParameters = (): AppParameters => {
    const params = new URLSearchParams(window.location.search);
    return { 
        documentId: params.get('documentId'),
        receiptId: params.get('receiptId'),
        userId: params.get('userId')
    }
}