import * as React from 'react';
import { Form, Card, Col, Row } from 'react-bootstrap';
import styled from 'styled-components'
import AppConfiguration from '../components/AppConfiguration';
import { AuthenticateRequest, UserInfo } from '../shared/Client';
import { APIClient } from '../shared/ApiClient';
import { Button } from '../components/common/Button';
import { H3 } from '../components/common/H3';
import { FadeIn } from '../components/animations/FadeIn';
import { Loader } from '../components/common/Loader';
import { MainError } from '../components/MainError';
import { MainLoader } from '../components/MainLoader';
import { IMainMessage, VariantEnum } from '../components/MainMessage';

interface ResetPasswordState {
    username?: string;
    password?: string;
    password2?: string;
    userId?: string;
    error: boolean;
    currentUser?: UserInfo;
}

interface ResetPasswordProps {
    onResetPassword: () => void;
    editMainMessage: (mainMessageProps: IMainMessage) => void;
    selectedUserId: string;
    mainMessageProps: IMainMessage;
    appConfiguration?: AppConfiguration;
    apiClient?: APIClient;
}

export default class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    constructor(props: ResetPasswordProps) {
        super(props);

        this.state = {
            error: false,
            userId: this.props.selectedUserId
        }
    }

    init = async () => {
        let currentUser = await this.props.apiClient!.getUserByGuid(this.props.selectedUserId);
        if (currentUser) {
            this.setState({ currentUser });
        }
        else {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Benutzer konnte nicht gefunden werden!'
                } as IMainMessage
            );
        }
    }

    forgotPassword = () => {

    }

    login = () => {

    }

    saveUserPassword = async () => {
        if (!this.state.password || !this.state.password2) {
            this.props.editMainMessage(
                {
                    show: true,
                    variant: VariantEnum.danger,
                    message: 'Bitte Felder ausfüllen!'
                } as IMainMessage
            );
        }
        else {
            if (this.state.password!.length < 6 || this.state.password2!.length < 6) {
                this.props.editMainMessage(
                    {
                        show: true,
                        variant: VariantEnum.danger,
                        message: 'Das Passwort muss mindestens 6 Zeichen umfassen.'
                    } as IMainMessage
                );
            }
            else {
                if (this.state.password === this.state.password2) {
                    await this.props.apiClient!.updatePerson(this.state.currentUser!.token!,
                        {
                            id: this.state.currentUser!.id,
                            password: this.state.password
                        } as UserInfo);

                    this.props.editMainMessage(
                        {
                            show: true,
                            variant: VariantEnum.success,
                            message: 'Passwort erfolgreich gespeichert!'
                        } as IMainMessage
                    );
                    this.props.onResetPassword();
                }
                else {
                    this.props.editMainMessage(
                        {
                            show: true,
                            variant: VariantEnum.danger,
                            message: 'Passwörter sind nicht identisch!'
                        } as IMainMessage
                    );
                }
            }
        }
    }

    handleKeyPress = (target: any) => {
        if (target.charCode === 13) {
            this.login();
        }
    }

    render() {
        return (
            <Loader
                loader={async () => { this.init() }}
                onLoad={<MainLoader message="Ihre Daten werden geladen..." />}
                onError={<MainError message={'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es später erneut...'} />}
                onSuccess={
                    <>
                        <Card>
                            <Card.Header>
                                <h5>Passwort ändern</h5>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Neues Passwort*</Form.Label>
                                                <Form.Control type="password" placeholder="******"
                                                    onChange={(e: any) => {
                                                        this.setState({ password: e.target.value });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Neues Passwort wiederholen*</Form.Label>
                                                <Form.Control type="password" placeholder="******"
                                                    onChange={(e: any) => {
                                                        this.setState({ password2: e.target.value });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button kind={VariantEnum.primary} style={{ float: 'right' }} buttonSize="small"
                                        onClick={this.saveUserPassword}>
                                        Speichern
                                </Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </>
                }
            />
        )
    }
}
