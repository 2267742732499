import * as React from 'react';
import { FormGroup, Modal, Form } from 'react-bootstrap';
import { EmailDto, UserInfo } from '../shared/Client';
import { Button } from '../components/common/Button'
import { VariantEnum } from '../components/MainMessage';

interface ModalEmailSendViewState {
    email?: string;
    emailCC?: string;
    emailTitle?: string;
    emailBody?: string;
    emailSignature?: string;
    emailSendDocuments?: boolean;
    emailReport?: boolean;
}

interface ModalEmailSendViewProps {
    onLoading: (loading: boolean) => void;
    onModalClose: (closing: boolean, emailModel: EmailDto) => void;
    onModalCancel: (cancel: boolean) => void;
    show: boolean;
    currentUser: UserInfo;
}

export default class ModalEmailSendView extends React.Component<ModalEmailSendViewProps, ModalEmailSendViewState> {
    constructor(props: ModalEmailSendViewProps) {
        super(props);

        this.state = {
            emailSignature: this.props.currentUser.firstName! + " " + this.props.currentUser.lastName! + "\n" 
                            + this.props.currentUser!.street! + "\n" 
                            + this.props.currentUser!.zipCode + " " + this.props.currentUser!.city + "\n"
                            + "Email: " + this.props.currentUser!.eMail +"\n"
                            + "Telefon: " + this.props.currentUser!.phone1
        }
    }

    resetEmail = () => {
        this.setState({
            email: '',
            emailCC: '',
            emailTitle: '',
            emailBody: '',
            emailSendDocuments:false,
            emailReport:false
        });
    }

    render() {
        return (
            <Modal show={this.props.show} className={"modal-90w"} size="lg">
                <Modal.Header>
                    <Modal.Title>Dokumentenversand</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row col-md-12">
                        <Form style={{ width: '100%' }}>
                            <FormGroup>
                                <Form.Label>Email Adresse</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.email}
                                    onChange={(e: any) => this.setState({ email: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>CC Email Adresse</Form.Label>
                                <Form.Control type="text"
                                    value={this.state.emailCC}
                                    onChange={(e: any) => this.setState({ emailCC: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Betreff</Form.Label>
                                <Form.Control type="text"
                                    placeholder="Ihr Reguliererschaden: Kein Datensatz geladen"
                                    value={this.state.emailTitle}
                                    onChange={(e: any) => this.setState({ emailTitle: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control as="textarea"
                                    style={{minHeight:'8rem'}}
                                    value={this.state.emailBody}
                                    onChange={(e) => this.setState({ emailBody: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Signatur</Form.Label>
                                <Form.Control as="textarea"
                                    style={{minHeight:'8rem'}}
                                    value={this.state.emailSignature}
                                    onChange={(e) => this.setState({ emailSignature: e.target.value })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Check type="checkbox"
                                    label="Schadendokumente anfügen"
                                    checked={this.state.emailSendDocuments}
                                    onChange={(e: any) => this.setState({ emailSendDocuments: e.target.checked })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Check type="checkbox"
                                    label="Letzten Prüfbericht anfügen"
                                    checked={this.state.emailReport}
                                    onChange={(e: any) => this.setState({ emailReport: e.target.checked })}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button kind={VariantEnum.secondary}
                         buttonSize="small"
                        onClick={() => {
                            this.props.onLoading(false);
                            this.props.onModalCancel(false);
                        }}>Schließen
                    </Button>
                    <Button kind={VariantEnum.primary}
                        onClick={() => {
                            let mail = {
                                email: this.state.email,
                                emailBody: this.state.emailBody + "<br><br>" 
                                           + this.props.currentUser.firstName! + " " + this.props.currentUser.lastName! + "<br>" 
                                           + this.props.currentUser!.street! + "<br>" 
                                           + this.props.currentUser!.zipCode + " " + this.props.currentUser!.city + "<br>"
                                           + "Email: " + this.props.currentUser!.eMail +"<br>"
                                           + "Telefon: " + this.props.currentUser!.phone1,
                                emailCC: this.state.emailCC,
                                emailReport: this.state.emailReport,
                                emailSendDocuments: this.state.emailSendDocuments,
                                emailTitle: this.state.emailTitle
                            } as EmailDto;
                            this.props.onLoading(false);
                            this.props.onModalClose(false, mail);
                            this.resetEmail();
                        }}>Send
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}