import * as React from 'react';
import { Modal } from './common/Modal';
import { Button } from './common/Button';
import { H1 } from './common/H1';

export interface IMainMessage {
    message?: string;
    variant?: VariantEnum;
    show: boolean;
}

export enum VariantEnum {
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    dark,
    light
}

interface MainMessageState {
}

interface MainMessageProps {
    onModalClose: (closing: boolean) => void;
    message?: string;
    variant: VariantEnum;
    show: boolean;
}

export class MainMessage extends React.Component<MainMessageProps, MainMessageState> {
    constructor(props: MainMessageProps) {
        super(props);

        this.state = {
            show: true,
            variant: VariantEnum.success
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.show} variant={this.props.variant}
                closeOnBackdropClick={true}
                onClose={() => this.props.onModalClose(false)}
                footer={<Button kind={this.props.variant} buttonSize="small"
                                onClick={() => { this.props.onModalClose(false) }}>
                            Schließen
                        </Button>}
            >
                <H1>{this.props.message}</H1>
            </Modal>
        )
    }
}