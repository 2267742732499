import * as React from 'react';
import styled from 'styled-components';
import theme from '../theme/theme';
import { Spinner } from './common/Spinner';
import { FadeIn } from './animations/FadeIn';

interface MainLoaderState {

}

interface MainLoaderProps {
    message?: string;
}

export class MainLoader extends React.Component<MainLoaderProps, MainLoaderState> {
    constructor(props: {}) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Container>
                <Spinner dotStyle={{ size: '1rem', margin: '.66rem', color: theme.colors.secondary }} />
                <Message>{this.props.message}</Message>
            </Container>
        )
    }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  animation: ${FadeIn} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
`;

const Message = styled.div`
  margin: 2rem;
  text-align: center;
`;
