import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';   
import 'url-search-params-polyfill';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-widgets/dist/css/react-widgets.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: "http://31667e169d494333964dd79331a99f70@pe-srv-dock-01:9000/23" });

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <App />
    </Sentry.ErrorBoundary>;
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
