import * as React from 'react';
import Portal from './Portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//require("./Lightbox.scss");

const css = `
.lightbox-container {
  background-color: rgba(0,0,0,0.5);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 0 10px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2001;
}

.lightbox-content {
  display: inline-block;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  vertical-align: middle;
}

.lightbox-contentHeightShim {
  display: inline-block;
  height: 100%;
  line-height: 0;
  vertical-align: middle;
}

.lightbox-carrusel {
  padding:0;
  margin: 4rem auto;
  text-align: center;
  overflow: hidden;
}

  .lightbox-carrusel > ul {
      padding: 0;
      margin: 0;
      list-style: none;
      transition: margin-top 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

.lightbox-thumbnail {
  position: relative;
  margin: 3rem 1rem;
  
  & > img {
      box-shadow: 0 0 7px 0 rgba(0,0,0,.2);
      transition: transform .3s ease-in-out, border .3s ease-in-out, box-shadow .3s ease-in-out;;
      &:hover {
          cursor: pointer;
          transform: scale(1.1);
          box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
      }
  }
  
  &.active > img {
      border: 1px solid transparent;
  }

  &.semi-active > img {
      border: 1px solid transparent;
  }
}

.lightbox-image {
  cursor: move;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
}

.lightbox-figure {
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1;
  min-height: 200px;
  min-width: 300px;
  margin: 0;
  text-align: center;
}

.lightbox-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 200px;
  overflow: hidden;
  text-align: center;
}

.document-accronym {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  padding: .4rem 1rem;
  opacity: 0.9;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2010;
}

.lightbox-toolbar {
  display: grid;
  grid-column-gap: 1rem;
  justify-content: center;
  grid-template-columns: repeat(5, auto);
  flex-direction: row;
  margin: 0 auto 5px;
  padding: 1rem;

  & > li {
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.5rem;
      background-color: rgba(0, 0, 0, 0.6);
      color: rgba(255, 255, 255, 0.53);
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform .3s ease-in-out, color .3s ease-in-out;
  }
  
  & > li:hover {
      color: white;
      transform: scale(1.1);
  }
}


.lightbox-arrow {
  border: none;
  cursor: pointer;
  outline: none;
  max-width: 80px;
  z-index: 1001;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  position: fixed;
  left: 79px;
  color: rgba(255, 255, 255, 0.53);
  font-size: 30px;
  transition: transform .3s ease-in-out, color .3s ease-in-out;

  &:hover {
      color: white;
      transform: scale(1.1);
  }
}

.lightbox-arrowNext {
  bottom: 0;
}

.lightbox-arrowPrev {
  top: 0;
}


.lightbox-imagesidebar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
}

.lightbox-closeBar {
  height: 40px;
  left: 0;
  position: absolute;
  text-align: right;
  top: 0;
  width: 100%;
}

.lightbox-closeButton {
  margin-right: 10px;
  border: none;
  cursor: pointer;
  height: 40px;
  outline: none;
  position: relative;
  right: 0;
  top: 0;
  width: 40px;
  color: rgb(255, 255, 255);
  background: transparent;
  font-size: 30px;
}


.lightbox-documentsidebar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  top: 0;
}

.lightbox-documentArrowNext {
  bottom: 0;
  left: 1600px;
}

.lightbox-documentArrowPrev {
  top: 0;
  left: 1600px;
}
`;

export function classes(...classNames: (string | null | undefined | boolean /*false*/)[]) {
  return classNames.filter(a => a && a !== "").join(" ");
}

Array.prototype.contains = function (this: any[], element: any) {
  return this.indexOf(element) !== -1;
};

export interface ImageAttachment {
  src: string;
  fullFileName: string;
  srcThumbnail: string;
  caption?: string;
  creationDate?: string;
}

export interface PdfAttachment {
  src: string;
  fullFileName: string;
  srcThumbnail: string;
  accronym: string;
  title: string;
  creationDate?: string;
}

interface LightboxProps {
  currentImage: number;
  imageAttachments: ImageAttachment[];

  currentDocument: number;
  pdfAttachments: PdfAttachment[];

  isOpen?: boolean;
  onSelect: (imageIndex: number, documentIndex: number, leftBarActive: boolean, renderAttachment: boolean) => void;
  leftBarActive: boolean;
  renderAttachment: boolean;
  onClose?: () => void;
  hMargin?: 60;
  vMargin?: 20;
  title?: React.ReactElement<any>;
}

interface LightboxState {
  windowHeight: number;
  windowWidth: number;
  imageData: { [src: string]: ImageData }
}

interface ImageData {
  src: string;
  naturalWidth: number;
  naturalHeight: number;
  width: number;
  height: number;
  left: number;  //(OffsetX)
  top: number; //(OffsetY)
  ratio: number; // %
  rotate: number; // degree
  scaleX: number; // Flip
  scaleY: number; // Flip
}

const imageSidebarMargin = 200;

export default class Lightbox extends React.Component<LightboxProps, LightboxState> {
  static hasImageExtension = (fileName: string) => {
    return ["jpg", "jpeg", "bmp", "png"].some(extension => fileName.toLowerCase().endsWith(extension));
  };

  constructor(props: LightboxProps) {
    super(props);

    this.state = {
      windowHeight: document.body.clientHeight,
      windowWidth: document.body.clientWidth,
      imageData: {},
    };
  }

  componentWillReceiveProps(nextProps: LightboxProps) {
    if (nextProps.isOpen) {
      window.addEventListener('keydown', this.handleKeyboardInput);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    } else {
      window.removeEventListener('keydown', this.handleKeyboardInput);
      window.removeEventListener('resize', this.handleResize);
    }

    const allSources = [
      ...nextProps.pdfAttachments.map(a => a.src),
      ...nextProps.imageAttachments.map(i => i.src)
    ];

    //Dictionary.getKeys(this.state.imageData)
    //  .filter(src => !allSources.contains(src))
    //  .forEach(src => delete this.state.imageData[src]);
  }

  close = (e: any) => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleKeyboardInput = (event: KeyboardEvent) => {
    event.preventDefault();

    switch (event.keyCode) {
      case 38: this.props.leftBarActive ? this.imageBar.handlePreviousPicture(event) : this.documentBar.handlePreviousDocument(event); break; //Up
      case 40: this.props.leftBarActive ? this.imageBar.handleNextPicture(event) : this.documentBar.handleNextDocument(event); break; //Down
      case 27: this.close(event); break; //Esc
      case 32: this.imageViewer && this.imageViewer.resetCurrentImage(); break; //Space
      case 37: this.imageViewer && this.imageViewer.rotateRight(event); break; //Right
      case 39: this.imageViewer && this.imageViewer.rotateLeft(event); break; //Left
    }
  };

  handleResize = () => {
    this.setState({
      windowHeight: document.body.clientHeight || 0,
      windowWidth: document.body.clientWidth || 0
    });
  };

  renderCloseButton() {
    return (
      <div className="lightbox-closeBar">
        <button
          title="Close (Esc)"
          className="lightbox-closeButton"
          onClick={this.props.onClose}>
          <FontAwesomeIcon icon="times" />
        </button>
      </div>
    );
  }

  imageBar!: ImageBar;
  documentBar!: DocumentBar;
  imageViewer?: ImageViewer | null;

  renderDialog() {
    if (!this.props.isOpen) return null;

    return (
      <div id="react-images-container"
        style={{ backgroundColor: "#525050" }}
        key="dialog"
        className="lightbox-container">
        <div>
          {this.props.title}
          <span className="lightbox-contentHeightShim" />

          {this.props.leftBarActive && this.renderImage()}
          {!this.props.leftBarActive && this.renderDocumentAttachment()}

          <ImageBar ref={ib => this.imageBar = ib!}
            currentImage={this.props.currentImage} imageAttachments={this.props.imageAttachments} isFocused={this.props.leftBarActive}
            onSelect={imageIndex => this.props.onSelect(imageIndex, this.props.currentDocument, true, false)} />

          <DocumentBar ref={db => this.documentBar = db!} windowWidth={this.state.windowWidth}
            currentDocument={this.props.currentDocument} documentAttachments={this.props.pdfAttachments} isFocused={!this.props.leftBarActive}
            onSelect={(documentIndex, render) => this.props.onSelect(this.props.currentImage, documentIndex, false, render)} />

          {this.props.onClose && this.renderCloseButton()}
        </div>
      </div>
    );
  }

  renderDocumentAttachment() {
    let da = this.props.pdfAttachments[this.props.currentDocument];

    if (da === undefined)
      return null;

    if (Lightbox.hasImageExtension(da.fullFileName))
      return this.renderImage();

    if (!this.props.renderAttachment)
      return null;

    return (
      <object data={da.src} type="application/pdf" style={this.getEmbedStyleDocument()} key={da.src}>
        <embed src={da.src} type="application/pdf" />
      </object>
    );
  }

  getEmbedStyleDocument(): React.CSSProperties {
    return {
      width: this.state.windowWidth - 440 + "px",
      height: 880 + "px",
      left: imageSidebarMargin + 20 + "px",
      top: this.props.vMargin + "px",
      position: "fixed"
    };
  }

  renderImage() {

    const { imageAttachments, currentImage, pdfAttachments, currentDocument } = this.props;
    const { windowHeight, windowWidth } = this.state;

    const image = this.props.leftBarActive ? imageAttachments[currentImage] : pdfAttachments[currentDocument];

    if (image === null || !Lightbox.hasImageExtension(image.fullFileName))
      return null;

    const imageData = this.state.imageData[image.src];

    return <ImageViewer ref={iw => this.imageViewer = iw} image={image} imageData={imageData} windowHeight={windowHeight} windowWidth={windowWidth} setImageData={newImageData => {
      this.state.imageData[newImageData.src] = newImageData;
      this.forceUpdate();
    }} />
  }

  render() {
    return (
      <div>
        <style>
          {css}
        </style>
        <Portal>
          {this.renderDialog()}
        </Portal>
      </div>
    );
  }
}


interface ImageBarProps {
  imageAttachments: ImageAttachment[];
  currentImage: number;
  isFocused: boolean;
  onSelect: (currentImage: number) => void;
}

export class ImageBar extends React.Component<ImageBarProps> {

  handleSideBarWheel = (e: React.WheelEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    const steps = (e.deltaY > 0) ? 1 : -1;

    const newImage = Math.min(Math.max(0, this.props.currentImage + steps), this.props.imageAttachments!.length - 1);

    this.props.onSelect(newImage);
  };


  handleAttachmentClicked = (e: React.MouseEvent<any>, index: number) => {

    e.stopPropagation();
    e.preventDefault();

    this.props.onSelect(index);
  };

  render() {
    return (
      <div className="lightbox-imagesidebar" onWheel={this.handleSideBarWheel}>
        {this.props.imageAttachments.length > 0 && this.renderArrowPrev()}
        {this.props.imageAttachments.length === 0 ? <h4 style={{ color: "lightgray" }}></h4> :
          <div className="lightbox-carrusel">
            <ul style={{
              marginTop: -((this.props.currentImage - 1) * 200) + "px",
            }}>
              {
                this.props.imageAttachments!.map((img, i) =>
                  <li key={i} className={classes("lightbox-thumbnail", i === this.props.currentImage && (this.props.isFocused ? "active" : "semi-active"))}>
                    <img 
                      src={img.srcThumbnail} 
                      onClick={e => this.handleAttachmentClicked(e, i)} 
                      alt={`${img.fullFileName}`}
                      title={`${img.fullFileName}`}/>
                  </li>
                )}
            </ul>
          </div>
        }
        {this.props.imageAttachments.length > 0 && this.renderArrowNext()}
      </div>
    );
  }

  renderArrowPrev() {
    if (this.props.currentImage === 0)
      return null;

    return (
      <button title="Previous (Left arrow key)"
        type="button"
        className="lightbox-arrow lightbox-arrowPrev"
        onClick={this.handlePreviousPicture}>
        <FontAwesomeIcon icon="arrow-up" />
      </button>
    );
  }

  renderArrowNext() {
    if (this.props.currentImage === (this.props.imageAttachments!.length - 1))
      return null;

    return (
      <button title="Next (Right arrow key)"
        type="button"
        className="lightbox-arrow lightbox-arrowNext"
        onClick={this.handleNextPicture}>
        <FontAwesomeIcon icon="arrow-down" />
      </button>
    );
  }

  handleNextPicture = (event: KeyboardEvent | React.MouseEvent<any>) => {
    if (this.props.currentImage === (this.props.imageAttachments!.length - 1)) return;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.onSelect(this.props.currentImage + 1);
  };

  handlePreviousPicture = (event: KeyboardEvent | React.MouseEvent<any>) => {
    if (this.props.currentImage === 0) return;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.onSelect(this.props.currentImage - 1);
  }
}

interface DocumentBarProps {
  documentAttachments: PdfAttachment[];
  currentDocument: number;
  isFocused: boolean;
  windowWidth: number;
  onSelect: (currentDocument: number, render: boolean) => void;
}

export class DocumentBar extends React.Component<DocumentBarProps> {

  handleDocumentSideBarWheel = (e: React.WheelEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    const steps: number = (e.deltaY > 0) ? 1 : -1;

    const newAttachment: number = Math.min(Math.max(0, this.props.currentDocument + steps), this.props.documentAttachments!.length - 1);

    this.props.onSelect(newAttachment, false);
  };

  handleDocumentAttachmentClicked = (e: React.MouseEvent<any>, documentIndex: number) => {

    e.stopPropagation();
    e.preventDefault();

    this.props.onSelect(documentIndex, true);
  };

  render() {
    return (
      <div className="lightbox-documentsidebar" style={{ left: this.props.windowWidth - 200 }} onWheel={this.handleDocumentSideBarWheel}>
        {this.props.documentAttachments.length > 0 && this.renderDocumentArrowPrev()}
        {this.props.documentAttachments.length === 0 ? <h4 style={{ color: "lightgray" }}>No documents</h4> :
          <div className="lightbox-carrusel">
            <ul style={{
              marginTop: -((this.props.currentDocument - 1) * 200) + "px",
            }}>
              {this.props.documentAttachments!.map((doc, i) =>
                <li key={i} className={classes("lightbox-thumbnail", i === this.props.currentDocument && (this.props.isFocused ? "active" : "semi-active"))}
                  title={doc.title}
                  onClick={e => this.handleDocumentAttachmentClicked(e, i)}>
                  <div className="document-accronym" style={{background: this.getColor(doc.title)}}>{doc.accronym}</div>
                  <img 
                    style={{width:'140px', marginTop:'10px'}}
                    src={doc.srcThumbnail} 
                    alt={`${doc.fullFileName}`}
                    title={`${doc.fullFileName}`}/>
                </li>
              )}
            </ul>
          </div>}
        {this.props.documentAttachments.length > 0 && this.renderDocumentArrowNext()}
      </div>
    );
  }

  colors = `#1f77b4
#aec7e8
#ff7f0e
#ffbb78
#2ca02c
#98df8a
#d62728
#ff9896
#9467bd
#c5b0d5
#8c564b
#c49c94
#e377c2
#f7b6d2
#7f7f7f
#c7c7c7
#bcbd22
#dbdb8d
#17becf
#9edae5`.split("\n");

  getColor(title: string) {
    return this.colors[Math.abs(this.hashCode(title)) % this.colors.length];
  }

  hashCode(str: string) {
    let hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      const character = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + character;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  renderDocumentArrowPrev() {
    if (this.props.currentDocument === 0)
      return null;

    return (
      <button title="Previous (Left arrow key)"
        type="button"
        className="lightbox-arrow lightbox-documentArrowPrev"
        onClick={this.handlePreviousDocument}
        style={{ left: this.props.windowWidth - 122 }}>
        <FontAwesomeIcon icon="arrow-up"  />
      </button>
    );
  }

  renderDocumentArrowNext() {
    if (this.props.currentDocument === (this.props.documentAttachments!.length - 1))
      return null;

    return (
      <button title="Next (Right arrow key)"
        type="button"
        className="lightbox-arrow lightbox-documentArrowNext"
        onClick={this.handleNextDocument}
        style={{ left: this.props.windowWidth - 122 }}>
        <FontAwesomeIcon icon="arrow-down" />
      </button>
    );
  }

  handleNextDocument = (event: KeyboardEvent | React.MouseEvent<any>) => {

    event.preventDefault();
    event.stopPropagation();

    if (this.props.currentDocument === (this.props.documentAttachments!.length - 1)) return;
    this.props.onSelect(this.props.currentDocument + 1, true);
  };

  handlePreviousDocument = (event: KeyboardEvent | React.MouseEvent<any>) => {

    event.preventDefault();
    event.stopPropagation();

    if (this.props.currentDocument === 0) return;
    this.props.onSelect(this.props.currentDocument - 1, true);
  }
}

interface ImageViewerProps {
  imageData?: ImageData;
  setImageData: (imageData: ImageData) => void;
  windowWidth: number;
  windowHeight: number;
  image: PdfAttachment | ImageAttachment;
}

export class ImageViewer extends React.Component<ImageViewerProps> {

  componentWillMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mousedown', this.handleMouseDown);
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mousedown', this.handleMouseDown);
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('wheel', this.handleWheel);
  }

  rotateLeft = (event: KeyboardEvent | React.MouseEvent<any>) => this.rotate(event, event.shiftKey ? 5 : 90);
  rotateRight = (event: KeyboardEvent | React.MouseEvent<any>) => this.rotate(event, event.shiftKey ? -5 : -90);

  rotate = (event: KeyboardEvent | React.MouseEvent<any>, rotateValue: number) => {
    const imageData = this.props.imageData;

    if (imageData === undefined)
      return;

    imageData.rotate += rotateValue;
    this.forceUpdate()
  };

  render() {
    return (
      <div>
        <img className="lightbox-image"
          draggable={true}
          src={this.props.image.src}
          style={this.getImageStyle(this.props.imageData)}
          ref={img => this.setImage(img)}
        />
        {this.renderButtonBar()}
      </div>
    );
  }

  getImageStyle(imageData: ImageData | undefined): React.CSSProperties | undefined {

    if (imageData === undefined)
      return { opacity: 0 };

    return {
      width: imageData.width + "px",
      height: imageData.height + "px",
      left: imageData.left + "px",
      top: imageData.top + "px",
      transform: `rotate(${imageData.rotate}deg) scale(${imageData.scaleX}, ${imageData.scaleY})`,
      position: "fixed"
    };
  }

  renderButtonBar() {
    return (
      <div className="lightbox-footer">
        <ul className="lightbox-toolbar">
          <li title="Rotate Counter-Clockwise ([Shift] + Arrow Left)" onClick={e => this.rotateRight(e)}><FontAwesomeIcon icon="undo" /></li>
          <li title="Zoom out (Wheel)" onClick={e => this.zoom(0.9, window.innerWidth / 2, window.innerHeight / 2)}><FontAwesomeIcon icon="search-minus" /></li>
          <li title="Reset (Space)" onClick={e => this.resetCurrentImage()}><FontAwesomeIcon icon={["far", "image"]} /></li>
          <li title="Zoom in (Wheel)" onClick={e => this.zoom(1.1, window.innerWidth / 2, window.innerHeight / 2)}><FontAwesomeIcon icon="search-plus" /></li>
          <li title="Rotate Clockwise ([Shift] + Arrow Right)" onClick={e => this.rotateLeft(e)}><FontAwesomeIcon icon="redo" /></li>
        </ul>
      </div>
    );
  }


  lastX?: number | null;
  lastY?: number | null;
  dragging: boolean = false;

  handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragging = true;
    this.lastX = e.clientX;
    this.lastY = e.clientY;
  };

  handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const imageData = this.props.imageData;

    if (imageData === null)
      return;

    if (this.dragging) {
      imageData!.left += (e.clientX - this.lastX!);
      imageData!.top += (e.clientY - this.lastY!);

      this.lastX = e.clientX;
      this.lastY = e.clientY;

      this.forceUpdate();
    }
  };

  handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.dragging = false;
    this.lastX = null;
    this.lastY = null;
  };

  handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const delta = Math.pow(1.1, -e.deltaY / 100);

    this.zoom(delta, e.clientX, e.clientY);
  };

  minSize = 100;

  zoom(delta: number, clientX: number, clientY: number) {
    const imageData = this.props.imageData;

    if (imageData === null)
      return;

    if ((imageData!.width < this.minSize || imageData!.height < this.minSize) && delta < 1)
      return;

    imageData!.ratio *= delta;

    const newWidth = imageData!.width * delta;
    const newHeight = imageData!.height * delta;

    function calculateRatio(min: number, value: number, size: number) {
      if (value === null)
        return 0.5;

      return (value - min) / size;
    }

    imageData!.left += (imageData!.width - newWidth) * calculateRatio(imageData!.left, clientX, imageData!.width);
    imageData!.top += (imageData!.height - newHeight) * calculateRatio(imageData!.top, clientY, imageData!.height);

    imageData!.width = newWidth;
    imageData!.height = newHeight;

    this.forceUpdate();
  }

  img?: HTMLImageElement | null;

  setImage(img: HTMLImageElement | null) {
    this.img = img;

    const imageData = this.props.imageData;
    const image = this.props.image;

    if (img)
      img.onload = () => {
        if (this.props.imageData === null)
          this.resetCurrentImage();
      };
  }

  resetCurrentImage() {


    const image = this.props.image;

    if (!image || this.img === null)
      return;

    const aspectRatio = this.img!.naturalWidth / this.img!.naturalHeight;

    let { windowWidth, windowHeight } = this.props;
    windowWidth -= (
      document.getElementsByClassName("lightbox-imagesidebar")[0].clientWidth + 
      document.getElementsByClassName("lightbox-documentsidebar")[0].clientWidth
    );

    let imgWidth = windowWidth;
    let imgHeight = windowHeight;

    if (windowHeight * aspectRatio > windowWidth) {
      imgHeight = windowWidth / aspectRatio;
    } else {
      imgWidth = windowHeight * aspectRatio;
    }

    imgWidth = Math.min(imgWidth * 0.9, this.img!.naturalWidth);
    imgHeight = Math.min(imgHeight * 0.9, this.img!.naturalHeight);

    this.props.setImageData({
      src: image.src,
      naturalWidth: this.img!.naturalWidth,
      naturalHeight: this.img!.naturalHeight,
      width: imgWidth,
      height: imgHeight,
      left: imageSidebarMargin + (windowWidth - imgWidth) / 2,  //(OffsetX)
      top: (windowHeight - imgHeight) / 2, //(OffsetY)
      ratio: imgWidth / this.img!.naturalWidth, // %
      rotate: 0, // degree
      scaleX: 1, // Flip
      scaleY: 1, // Flip
    });

    this.forceUpdate();
  }
}


