import * as React from 'react';
import theme from '../theme/theme';
import { Checkbox } from '../components/common/Checkbox';
import styled from 'styled-components'
import { Modal } from '../components/common/Modal';
import { Button } from '../components/common/Button';
import { VariantEnum } from '../components/MainMessage';

interface ModalFinishViewState {
    sendButton: boolean;
}

interface ModalFinishViewProps {
    onLoading: (loading: boolean) => void;
    onModalClose: (closing: boolean) => void;
    onModalCancel: (cancel: boolean) => void;
    show: boolean;
    showCheckBox: boolean;
    outputMail: string;
}

export default class ModalFinishView extends React.Component<ModalFinishViewProps, ModalFinishViewState> {
    constructor(props: ModalFinishViewProps) {
        super(props);

        this.state = {
            sendButton: !this.props.showCheckBox
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.show} variant={VariantEnum.light}
                closeOnBackdropClick={true}
                onClose={() => this.props.onModalClose(false)}
                header={<h4>Prüfbericht senden an: {this.props.outputMail}</h4>}
            >
                <>
                    <p>Keine nachträgliche Änderung möglich.</p>
                    {this.props.showCheckBox &&
                        <Checkbox
                            label={<>Inhaltliche Prüfung zum Ausmaß und Umfang wurde durch den Regulierer ausdrücklich geprüft</>}
                            checkboxColor={theme.colors.text.primary}
                            borderColor={theme.colors.primary}
                            defaultValue={this.state.sendButton}
                            onChange={(checked) => this.setState({ sendButton: checked })}
                        />
                    }
                    <br />
                    <DemoActions>
                        <Button kind={VariantEnum.secondary}
                            onClick={() => {
                                this.props.onLoading(false)
                                this.props.onModalCancel(false)
                            }}>Abbrechen</Button>
                        {' '}
                        <Button kind={VariantEnum.primary}
                            hidden={!this.state.sendButton}
                            onClick={() => {
                                this.props.onLoading(false)
                                this.props.onModalClose(false)
                            }}>Vorgang Abschließen</Button>
                    </DemoActions>
                </>
            </Modal>
        )
    }
}


const DemoActions = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  margin-bottom: 2rem;
`