import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ModalDocumentViewState {
}

interface ModalDocumentViewProps {
    onLoading: (loading: boolean) => void;
    onModalClose: (closing: boolean) => void;
    show: boolean;
    content: string;
}

export default class ModalDocumentView extends React.Component<ModalDocumentViewProps, ModalDocumentViewState> {
    constructor(props: ModalDocumentViewProps) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Modal show={this.props.show} className={"modal-90w"} size="lg">
                <Modal.Body>
                    <object style={{ width: '100%', minWidth: '100%', height: '800px', minHeight: '800px' }}
                        data={"data:application/pdf;base64," + this.props.content} type="application/pdf">
                             <embed src={"data:application/pdf;base64," + this.props.content} type="application/pdf" />
                    </object>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={() => {
                            this.props.onLoading(false)
                            this.props.onModalClose(false)
                        }}>Schließen</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}