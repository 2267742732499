import * as React from 'react';
import { Button, Form } from 'react-bootstrap';

interface MainErrorState {
}

interface MainErrorProps {
    message?: string;
}

export class MainError extends React.Component<MainErrorProps, MainErrorState> {
    constructor(props: {}) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <Form.Text>{this.props.message}</Form.Text>
                <Button variant="primary" onClick={() => window.location.reload(false)}>
                    Erneut versuchen
                </Button>
            </div>
        )
    }
}