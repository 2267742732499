import * as React from 'react';

interface StarRatingState {
    starsSelected: number
}

interface StarRatingProps {
    onStarRating: (stars: number) => void;
}

export class StarRating extends React.Component<StarRatingProps, StarRatingState> {
    constructor(props: StarRatingProps) {
        super(props);

        this.state = {
            starsSelected: 0
        }
    }

    change(starsSelected: number) {
        this.setState({ starsSelected })
        this.props.onStarRating(starsSelected);
    }

    render() {
        return (
            <div className="star-rating">
                {[1, 2, 3, 4, 5].map((n, i) =>
                    <div className={(i < this.state.starsSelected) ? "star selected" : "star"}
                        onClick={() => this.change(i + 1)}>
                    </div>
                )}
            </div>)
    }
}
